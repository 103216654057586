import Especialidad, { createEspecialidad } from "../dtos/especialidad/Especialidad";
import Estado from "../dtos/Estado";
import NivelEspecialidad from "../dtos/especialidad/NivelEspecialidad";
import AreaEspecialidad, { createAreaEspecialidad } from "../dtos/especialidad/AreaEspecialidad";
import store from "../store/store";
import { error, success } from "../store/alerts/alertActions";
import { history } from "../helpers/history";
const { apiURLs, roleConstants, estadosConstans, routePaths, paginado } = require("../utils/Constants");
const { default: requestService } = require("../utils/request.service");

//tener en cuenta cuando se agregue limite por el paginado que este metodo tmb se usa en selects
const getEspecialidades = async (filtros = {}) => {
  let especialidades = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_ESPECIALIDAD, filtros)
  response.data.elementos.forEach(especialidad => {
    especialidades.push(createEspecialidad(especialidad))
  });
  let especialidadesConcretas = await Promise.all(especialidades)
  return { page: response.data.page, cantidad: response.data.cantidad, data: especialidadesConcretas };
}

const getEspecialidadesByVariantes = async (filtros = {}) => {
  let especialidades = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_ESPECIALIDAD + "/xx/getEspecialidadesByVariantes", filtros)
  response.data.elementos.forEach(especialidad => {
    especialidades.push(createEspecialidad(especialidad))
  });
  let especialidadesConcretas = await Promise.all(especialidades)
  return { page: response.data.page, cantidad: response.data.cantidad, data: especialidadesConcretas };
}

//TODO: Cambiar por el rol de Supervisor
const getSupervisors = async (filtros = {}) => {
  filtros['limit'] = 100;
  filtros['rol'] = roleConstants.ADMIN;

  return await requestService.sendRequest('GET', apiURLs.API_ADMIN_USUARIOS_URL, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

//TODO: Cambiar por el rol de Analista
const getAnalysts = async (filtros = {}) => {
  filtros['limit'] = 100;
  filtros['rol'] = roleConstants.ADMIN;
  return await requestService.sendRequest('GET', apiURLs.API_ADMIN_USUARIOS_URL, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

const getAreas = async (filtros = {}) => {
  //filtros['limit'] = 100;
  let areas = [];
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_AREA, filtros)
  response.data.elementos.forEach(area => {
    areas.push(createAreaEspecialidad(area));
  });
  let areasConcretas = await Promise.all(areas);
  return { cantidad: response.data.cantidad, data: areasConcretas };
}

const getAreasByVariantes = async (filtros = {}) => {
  //filtros['limit'] = 100;
  let areas = [];
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_AREA + "/xx/getAreasByVariantes", filtros)
  response.data.elementos.forEach(area => {
    areas.push(createAreaEspecialidad(area));
  });
  let areasConcretas = await Promise.all(areas);
  return { cantidad: response.data.cantidad, data: areasConcretas };
}

const getEspecialidadesByCargo = async (cargoId) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_CARGO + `/especialidadesByCargo/${cargoId}`)
  return response.data;
}

const getNiveles = async (filtros = {}) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_NIVEL, filtros)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
  return response;
}

const getNivelesTerna = async () => {
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_NIVEL, { esCargo: false })
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
  return response;
}

const getNivelesCargo = async () => {
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_NIVEL, { esCargo: true })
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
  return response;
}

const deactivate = async (especialidad, resolve) => {
  await changeState(especialidad, estadosConstans.DESHABILITADO)
  if (resolve) await resolve();
  await store.dispatch(success(<>Se desactivo la especialidad <b>{especialidad.nombre}</b> correctamente.</>));
}

const activate = async (especialidad, resolve) => {
  await changeState(especialidad, estadosConstans.BORRADOR)
  if (resolve) await resolve();
  await store.dispatch(success(<>Se activo la especialidad <b>{especialidad.nombre}</b> correctamente.</>));
}

const sendToAuthorize = async (especialidad) => {
  await changeState(especialidad, estadosConstans.PARA_SER_AUTORIZADO, "Se envió a autorizar la especialidad correctamente.")
}

const createNewEspecialidad = async (especialidad, resolve) => {
  let { area, codDad, ...body } = especialidad;
  const payload = { area_id: area, cod_dad: codDad, nivel_id: 'M', ...body };
  
  try {
    const resp = await requestService.sendRequest('POST', apiURLs.API_MAESTROS_ESPECIALIDAD, payload);
    //console.log("esto es el log:", JSON.stringify(resp.data, null, 2));

    resolve(especialidad); 
    const mensaje = resp.data.mensaje;
    
    if (mensaje) {
      //console.log("Mensaje recibido:", mensaje);
      return mensaje;
    } 
    
    return null;
      
  } catch (e) {
    store.dispatch(error(e.response.data.message));
    return e.response.data.message;
  }
};


const editEspecialidad = async (especialidad, resolve) => {
  let { codDad, ...body } = especialidad
  let area = Object.keys(especialidad.area).length === 0 ? especialidad.area : null;
  try {
    const resp = await requestService.sendRequest('PATCH', apiURLs.API_MAESTROS_ESPECIALIDAD + `/${especialidad.id}`, { area_id: area, cod_dad: codDad, nivel_id: 'M', ...body })
    await resolve(resp.data)
  }
  catch (e) {
    store.dispatch(error(e.response.data.message))
  }
}

const saveDraft = async (especialidad, resolve, mensaje) => {
  resolve(especialidad);
  const mensajeExiste = mensaje || <>Se guardó correctamente la especialidad <b>{especialidad.nombre}</b> como borrador.</>;
  if (mensaje) {
    await store.dispatch(error(mensajeExiste));
  } else {
    await store.dispatch(success(mensajeExiste));
  }
}

const sendToRevice = async (especialidad) => {
  await changeState(especialidad, estadosConstans.EN_REVISION, "Se envió a revisar la especialidad correctamente.")
}

const approve = async (especialidad, resolve) => {
  await changeState(especialidad, estadosConstans.APROBADO)
  if (resolve) await resolve();
  await store.dispatch(success(<>Se aprobo la especialidad <b>{especialidad.nombre}</b> correctamente.</>));
}

const publish = async (especialidad, resolve) => {
  await changeState(especialidad, estadosConstans.PUBLICADO)
  if (resolve) await resolve();
  await store.dispatch(success(<>Se publico la especialidad <b>{especialidad.nombre}</b> correctamente.</>));
}

const changeState = async (especialidad, newState, message, resolve) => {
  await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_ESPECIALIDAD + `/${especialidad.id}/estado`, { estado: newState })
}

const deleteEspecialidad = async (especialidad) => {
  await requestService.sendRequest('DELETE', apiURLs.API_MAESTROS_ESPECIALIDAD + `/${especialidad.id}`)
  await store.dispatch(success(<>Se elimino la especialidad <b>{especialidad.nombre}</b> correctamente.</>))
}

export {
  getEspecialidades,
  getAreas,
  getAreasByVariantes,
  getNiveles,
  saveDraft,
  sendToAuthorize,
  sendToRevice,
  approve,
  publish,
  getAnalysts,
  getSupervisors,
  deleteEspecialidad,
  activate,
  deactivate,
  editEspecialidad,
  createNewEspecialidad,
  getNivelesCargo,
  getNivelesTerna,
  getEspecialidadesByCargo,
  getEspecialidadesByVariantes,
}