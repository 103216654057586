import React, { useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { saveFilters } from '../../store/search/searchActions';
import { tipoBusquedaInforme } from '../../utils/Constants';
import { FormSearchByTerna } from './FormSearchByTerna'
import { FormTableByTerna } from './FormTableByTerna'
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import { Loader } from '../../ui/Loader';
import Dialog from '../../generics/dialog';
import { agregarDescargas, OPCIONES_DESCARGAS } from '../../store/descargas/descargasActions.';
import { error } from '../../store/alerts/alertActions';
import { getTernas } from '../../ternas/TernaService';
import PrivateComponent from '../../authentication/PrivateComponent';
import { PERMISOS_MAP } from '../../utils/Permisos';

export const SearchByTerna = () => {
    const [inputsForm, setInputsForm] = useState({});
    const [resetChecks, setResetChecks] = useState(false);
    const [elementos, setElementos] = useState();
    const [cantidadElementos, setCantidadElementos] = useState();
    const [page, setPage] = useState(1);

    const [searching, setSearching] = useState(false);

    
    const {
        arrActivas,
        arrCompletadas
    } = useSelector(st => st.descargas);
    
    const tituloDescargas = "Descarga en proceso" + (arrCompletadas.length > 0 ? ` (${arrCompletadas.length})` : "");
    
    const searchState = useSelector(st => st.search)
    const dispatch = useDispatch();

    const cleanResults = () => {
        setSearching(false)
        setElementos("");
        setCantidadElementos("");
        setResetChecks(true);
    }

    const searchInformeByTerna = async (filtros, isMountedRef) => {
        dispatch(saveFilters(filtros))

        setSearching(true);
        if (!filtros.page) filtros.page = 1;
        setPage(filtros.page); //Reset page

        try {
            const resp = await getTernas(filtros);
            console.log({ resp })
            setElementos(resp.data)
            setCantidadElementos(Number(resp.cantidad));
        }
        catch (err) { console.log(err.message) }
        finally { setSearching(false); }
    };

    const handleChangePageByTerna = (event, value) => {
        if (searchState.page != value) {
            const filtros = { ...searchState, page: value }
            searchInformeByTerna(filtros)
        }
    }

    const cantidadMaximaElementos = 1000;

    const handleClickGenerar = async () => {
        let filtros = { ...inputsForm }
        if (filtros.page) delete filtros.page
        try {
            let objDescarga = {
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
            };

            switch (filtros.tipoBusqueda) {
                case tipoBusquedaInforme.TITULO: {
                    objDescarga = {
                        ...objDescarga,
                        title: cantidadElementos > cantidadMaximaElementos ? "Informe / Ternas / Titulos (CSV)" : "Informe / Ternas / Titulos (PDF)",
                        opcion: cantidadElementos > cantidadMaximaElementos ? OPCIONES_DESCARGAS.CSV.INFORME_TERNA_TITULOS : OPCIONES_DESCARGAS.PDF.INFORME_TERNA_TITULOS,
                        // fileName
                    }
                    break
                }
                case tipoBusquedaInforme.RESOLUCIONES: {
                    objDescarga = {
                        ...objDescarga,
                        title: cantidadElementos > cantidadMaximaElementos ? "Informe / Ternas / Resoluciones (CSV)" : "Informe / Ternas / Resoluciones (PDF)",
                        opcion: cantidadElementos > cantidadMaximaElementos ? OPCIONES_DESCARGAS.CSV.INFORME_TERNA_TITULOS : OPCIONES_DESCARGAS.PDF.INFORME_TERNA_TITULOS,
                        // fileName
                    }
                    break
                }
                case tipoBusquedaInforme.PROCEDENCIAS: {
                    objDescarga = {
                        ...objDescarga,
                        title: cantidadElementos > cantidadMaximaElementos ? "Informe / Ternas / Procedencias (CSV)" : "Informe / Ternas / Procedencias (PDF)",
                        opcion: cantidadElementos > cantidadMaximaElementos ? OPCIONES_DESCARGAS.CSV.INFORME_TERNA_TITULOS : OPCIONES_DESCARGAS.PDF.INFORME_TERNA_TITULOS,
                        // fileName
                    }
                    break
                }
                default:
                    throw new Error("El tipo de busqueda es incorrecto");
            }

            dispatch(agregarDescargas([objDescarga]));

        } catch (err) {
            dispatch(error(err.message))
        }
    }

    return (
        <ScreenWithFabButtons
            searchButtonDisabled={searching}
            cleanButtonDisabled={searching || !elementos}
            hideSearchButton={false}
            hideCleanButton={false}
        >
            <h1>Informes</h1>
            <h3>Ternas</h3>

            {arrCompletadas.length > 0 && (
                <Alert variant="warning" className="mt-3">
                    📂 {tituloDescargas}
                </Alert>
            )}

            <FormSearchByTerna
                inputsForm={inputsForm}
                setInputsForm={setInputsForm}
                resetChecks={resetChecks}
                setResetChecks={setResetChecks}
                cleanResults={cleanResults}
                searchInformeByTerna={searchInformeByTerna}
            />
            {!searching && <>
                <FormTableByTerna
                    elementos={elementos}
                    tipoBusqueda={inputsForm?.tipoBusqueda}
                    cantidadElementos={cantidadElementos}
                    handleChangePage={handleChangePageByTerna}
                    page={page}
                ></FormTableByTerna>

                {(cantidadElementos > 0 || cantidadElementos === 0) &&
                    <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                        {/*CSV_INFORME_TERNA_TITULOS*/}
                        <Dialog
                            const key={(arrActivas && Array.isArray(arrActivas) && arrActivas.some(item => item.opcion === "CSV_INFORME_TERNA_TITULOS"))
                            ? `pdf-${arrActivas.findIndex(item => item.opcion === "CSV_INFORME_TERNA_TITULOS")}`
                            : "pdf"}
                            action={handleClickGenerar}
                            title={cantidadElementos > cantidadMaximaElementos ? "Generar Reporte (CSV)" : "Informe Total"}
                            variant="primary"
                            size="md"
                            btnConfirmText="Generar"
                            btnVariant="primary"
                            btnText={arrActivas.some(item => item.opcion === "CSV_INFORME_TERNA_TITULOS") ? (
                                <>
                                    <Spinner animation="border" size="sm" style={{ color: 'red' }} /> {" Generando Inf. Total"}
                                </>
                            ) : "Informe Total"}
                            isLoadingText={"Generando informe"}
                            body= {<span>¿Estás seguro de generar el informe?</span>}
                            disabled={arrActivas.some(item => item.opcion === "CSV_INFORME_TERNA_TITULOS")}
                        />
                    </PrivateComponent>
                }
            </>
            }

            {searching && <Loader></Loader>}
        </ScreenWithFabButtons>
    )
}
