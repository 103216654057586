import React from "react";
import { Accordion, Card, Col, Row, Badge } from "react-bootstrap";

export const UnificacionAccordion = ({ unificacion }) => {
    // Función que asigna el color según el estado
    const getEstadoColor = (estado) => {
        switch (estado) {
            case 'Aprobado':
                return 'success';  // Verde
            case 'Deshabilitado':
                return 'danger';  // Rojo
            case 'Pendiente':
                return 'warning';  // Amarillo
            default:
                return 'secondary';  // Gris
        }
    };

    const formatData = (procedencia, resolucion) => {
        return `${procedencia}${procedencia && resolucion ? ' | ' : ''}${resolucion} | `;
    };

    return (
        <Accordion className="form-group">
            {unificacion.unificaciones.map(unificacion => (
                <Card key={unificacion.id}>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <Row className="align-items-center">
                            <Col xs={12} md={8} lg={9} xl={10}>
                                <h5>{"Terna Unificada: " + unificacion.terna.titulo.nombre}</h5>
                                <span className="accordion-header-text">
                                    {formatData(unificacion.terna.procedencia.nombre, unificacion.terna.resolucion.nombre)}
                                    <Badge variant={getEstadoColor('Deshabilitado')}>
                                        Deshabilitado
                                    </Badge>
                                </span>
                            </Col>
                        </Row>
                    </Accordion.Toggle>
                </Card>
            ))}
        </Accordion>
    );
};
