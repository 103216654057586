import { makeValidFileName } from "../utils/commons";
import { apiURLs } from "../utils/Constants";
import requestService from "../utils/request.service";

const NOMBRE_TIPO_INFORME = {
    "TOTAL": " - TOTAL",
    "DESTINATARIOS": " - DESTIONATARIOS",
    "SIN_CONCURRENCIAS": " - SIN CONCURRENCIAS",
}

const addTipoInformeTotal = (nombre) => {
    return nombre + NOMBRE_TIPO_INFORME.TOTAL
}

const addTipoInformeDestinatarios = (nombre) => {
    return nombre + NOMBRE_TIPO_INFORME.DESTINATARIOS
}

const addTipoInformeSinConcurrencias = (nombre) => {
    return nombre + NOMBRE_TIPO_INFORME.SIN_CONCURRENCIAS
}

/// vadecum
export const downloadPDFInformeCruzado = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "area_id"
        "cargo_id"
    */
    let defaultFileName = `informe-vademecum`
    if (filtros.area_id) defaultFileName = defaultFileName + `-area-${filtros.area_id}`
    if (filtros.cargo_id) defaultFileName = defaultFileName + `-cargo-${filtros.cargo_id}`

    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO_1RA_VISTA;
    try {
        const response = await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadCSVInformeCruzado = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        csv: bool
    */
    const defaultFiltros = { csv: true }
    let defaultFileName = `informe-vademecum`
    if (filtros.area_id) defaultFileName = defaultFileName + `-area-${filtros.area_id}`
    if (filtros.cargo_id) defaultFileName = defaultFileName + `-cargo-${filtros.cargo_id}`

    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO_1RA_VISTA;

    try {
        await requestService.iniciarDescargaSegundoPlanoCSV(
            url,
            { ...filtros, ...defaultFiltros },
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}
///

export const downloadPDFInformeIncumbByTernaPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        titulo
        procedencia_id
        resolucion_id
        area_id
        otros_titulos
        titulos
    */
    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA_1RA_VISTA;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }

}

export const downloadCSVInformeIncumbByTernaPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        csv: bool
    */
    const defaultFiltros = { csv: true }
    const defaultFileName = `informe-incumbencias-terna-sin-filtros`;

    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA_1RA_VISTA;

    try {
        await requestService.iniciarDescargaSegundoPlanoCSV(
            url,
            { ...filtros, ...defaultFiltros },
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}


export const downloadCSVInformeIncumbByTernaSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFiltros = { csv: true }

    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA;

    try {
        await requestService.iniciarDescargaSegundoPlanoCSV(
            url,
            { ...filtros, ...defaultFiltros },
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}



export const downloadPDFInformeIncumbByTernaSegundaVistaInformativo = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeDestinatarios(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeIncumbByTernaSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeIncumbByTernaSegundaVistaSinConcurrencias = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_INCUMB_BY_TERNA;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeSinConcurrencias(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTopologico = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "apendice"
        "folio"
        "area_id"
    */
    const defaultFileName = `informe-topologico`;
    const url = apiURLs.API_INFORMES_GENERAR_TOPOLOGICO;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}


export const downloadCSVInformeTopologico = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "apendice"
        "folio"
        "area_id"
    */
    const defaultFiltros = { csv: true }
    const defaultFileName = `informe-topologico`;
    const url = apiURLs.API_INFORMES_GENERAR_TOPOLOGICO;
    try {
        await requestService.iniciarDescargaSegundoPlanoCSV(
            url,
            { ...filtros, ...defaultFiltros },
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

/// aréa - cargo
export const downloadCSVTernasByAreaCargoPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-ternas-por-area-cargo-1ra-vista`;
    const defaultFiltros = { csv: true }
    const url = apiURLs.API_INFORMES_GENERAR_CRUZADOS;
    try {
        await requestService.iniciarDescargaSegundoPlanoCSV(
            url,
            { ...filtros, ...defaultFiltros },
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}


export const downloadPDFTernasByAreaCargoPrimeraVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "cargo"
        "asignatura"
        "especialidad"
        "area"
        "tipo_escuela"
    */
    const defaultFileName = `informe-ternas-por-area-cargo-1ra-vista`;
    const url = apiURLs.API_INFORMES_GENERAR_CRUZADOS;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFTernasByAreaCargoPrimeraVistaInformativo = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "cargo_id"
        "asignatura_id"
        "especialidad_id"
        "area_id"
        "tipo_escuela_id"
    */
    const defaultFileName = `informe-ternas-por-area-cargo-1ra-vista - inf destinatarios`;
    const url = apiURLs.API_INFORMES_GENERAR_CRUZADOS;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            { ...filtros, informativo: true },
            addTipoInformeDestinatarios(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}
///

export const downloadPDFTernasByAreaCargoSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        "cargo_id"
        "asignatura_id"
        "especialidad_id"
        "area_id"
        "tipo_escuela_id"
    */
    const defaultFileName = `informe-ternas-area-cargo - inf total`;
    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO;
    console.log("ACA 1", { config })

    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? `${makeValidFileName(config.fileName)} - inf total` : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeCruzadoPorCalidad = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:
        area_id
        asignatura_id
        cargo_id
        especialidad_id
        tipo_incumbencia_id
        serializeAll
    */
    const params = { ...filtros }
    if (params.page) delete params.page
    if (params.limit) delete params.limit

    const defaultFileName = `informe-ternas-area-cargo`;
    console.log({ config })
    const url = apiURLs.API_INFORMES_GENERAR_CRUZADOS_POR_CALIDAD;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            params,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadCSVTernasByAreaCargoSegundaVista = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-ternas-area-cargo`;
    const defaultFiltros = { csv: true }
    const url = apiURLs.API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO;
    console.log("ACA 2", { config })
    try {
        await requestService.iniciarDescargaSegundoPlanoCSV(
            url,
            { ...filtros, ...defaultFiltros },
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadCSVInformeTernaTitulos = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-terna-titulos`
    const defaultFiltros = { csv: true }

    const url = apiURLs.API_INFORMES_GENERAR_TERNA_TITULOS;
    try {
        const response = await requestService.iniciarDescargaSegundoPlanoCSV(
            url,
            { ...filtros, ...defaultFiltros },
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTernaTitulos = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-terna-titulos`
    const url = apiURLs.API_INFORMES_GENERAR_TERNA_TITULOS;
    try {
        const response = await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTernaResoluciones = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:

    */
    const defaultFileName = `informe-terna-resoluciones`
    const url = apiURLs.API_INFORMES_GENERAR_TERNA_RESOLUCIONES;
    try {
        const response = await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeTernaProcedencias = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    /* FILTROS:

    */
    const defaultFileName = `informe-terna-procedencias`
    const url = apiURLs.API_INFORMES_GENERAR_TERNA_PROCEDENCIAS;
    try {
        const response = await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}

export const downloadPDFInformeEquivalencias = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    const defaultFileName = `informe-incumbencias-terna${filtros.terna_id}`;
    const url = apiURLs.API_INFORMES_GENERAR_EQUIVALENCIAS;
    try {
        await requestService.iniciarDescargaSegundoPlanoPDF(
            url,
            filtros,
            addTipoInformeTotal(config.fileName ? makeValidFileName(config.fileName) : defaultFileName),
            abortSource,
        )
        resolve()
    }
    catch (err) {
        reject(err)
    }
}// Nuevo: Obtener la lista de documentos generados
export const getDocumentos = async (filtros = {}, resolve, reject, abortSource = null) => {
    try {
        const url = apiURLs.API_DESCARGA_INFORME; // Usando la constante definida
        
        if (abortSource) {
            filtros.signal = abortSource.signal; // Solo agregar si existe
        }

        const response = await requestService.sendRequest('GET', url, filtros);
        resolve(response.data);
    } catch (err) {
        reject(err);
    }
};

// Nuevo: Consultar el estado de un documento
export const getEstadoDocumento = async (idDocumento, resolve, reject, abortSource = null) => {
    if (!idDocumento) {
        return reject(new Error("El ID del documento es obligatorio."));
    }

    try {
        const url = apiURLs.API_DESCARGA_ESTADO_INFORME; // Usando la constante definida
        const params = { id_documento: idDocumento };

        if (abortSource) {
            params.signal = abortSource.signal; // Solo agregar si existe
        }

        const response = await requestService.sendRequest('GET', url, params);
        resolve(response.data);
    } catch (err) {
        reject(err);
    }
};

// Nuevo: Solicitar la generación de un PDF topológico
export const generarDescargaPDF = async (formato, params = {}, resolve, reject, abortSource = null) => {
    try {
        const url = apiURLs.API_GENERAR_DESCARGA_PDF;

        // Llamar a la función correcta según el formato
        const response = formato === 'CSV'
            ? await requestService.downloadCSVInforme(url, params, "documento", abortSource)
            : await requestService.downloadPDFInforme(url, params, "documento", abortSource);

        console.log(response);
        resolve();
    } catch (err) {
        reject(err);
    }
};


export const downloadTESTEOCompletar = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    setTimeout(() => {
        resolve()
    }, 3000)
}

export const downloadTESTEOCancelar = async (filtros = {}, resolve, reject, abortSource, config = {}) => {
    setTimeout(() => {
        reject()
    }, 3000)
}
