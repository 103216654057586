
import { alertConstants } from "../../utils/Constants"

const initialState = {
    show: false,
    type: "",
    message: "",
    scroll: true,
}

const defaultScroll = true

const alertReduce = (state = initialState, action) => {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                show: true,
                type: 'success',
                message: action.message,
                scroll: action.scroll != undefined ? action.scroll : defaultScroll
            }

        case alertConstants.ERROR:
            return {
                show: true,
                type: 'danger',
                message: action.message,
                scroll: action.scroll != undefined ? action.scroll : defaultScroll
            }

        case alertConstants.WARNING:
            return {
                show: true,
                type: 'warning',
                message: action.message,
                scroll: action.scroll != undefined ? action.scroll : defaultScroll
            }

        case alertConstants.INFO:
            return {
                show: true,
                type: 'info',
                message: action.message,
                scroll: action.scroll != undefined ? action.scroll : defaultScroll,
            }

        case alertConstants.MULTIPLE:
            const messageFormated = action.message.map(alert => {
                return {
                    alert,
                    show: true
                }
            })
            return {
                show: true,
                type: 'multiple',
                message: messageFormated,
                scroll: action.scroll != undefined ? action.scroll : defaultScroll,
            }

        case alertConstants.HIDE_ONE_ALERT:
            const newMessage = state.message.map((element, index) => {
                if (index == action.payload) element.show = false;
                return element
            })

            return {
                show: true,
                type: 'multiple',
                message: newMessage,
                scroll: action.scroll != undefined ? action.scroll : defaultScroll,
            }

        case alertConstants.CLEAR:
            return initialState

        default:
            return state
    }
}

export default alertReduce