import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, Col, Row, Spinner, Badge } from 'react-bootstrap';
import Input from '../../../../ui/Input';

const MockedIncumbenciaAccordion = ({
  ternasFromIncumbencias,
  arrIncumbencias = [],
  selectedChecks = {},
  handleClickCheck,
  handleLoadDetail,
  setSelectedChecks,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const cardBodyRef = useRef(null);

  useEffect(() => {
    if (cardBodyRef.current) {
      // Establece el padding en 0 con !important
      cardBodyRef.current.style.setProperty('padding', '0', 'important');
    }
  }, []);

  const handleClickLoadDetail = async () => {
    setIsLoading(true);
    try {
      await handleLoadDetail();
      setShowErrorMsg(false);
    } catch {
      setShowErrorMsg(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeAll = (e) => {
    e.stopPropagation();
    const allChecked = e.target.checked;
    const updatedChecks = {};

    arrIncumbencias.forEach((ctEl) => {
      updatedChecks[ctEl.id] = allChecked;
    });

    setSelectedChecks(updatedChecks);
  };

  const handleChangeIndividual = (e, ctIncumbencia) => {
    e.stopPropagation();
    const updatedChecks = { ...selectedChecks, [ctIncumbencia.id]: e.target.checked };
    setSelectedChecks(updatedChecks);
  };

  const isAllSelected = () => {
    return (
      arrIncumbencias.length > 0 &&
      arrIncumbencias.every((ctEl) => selectedChecks[ctEl.id])
    );
  };

  // Encabezado principal (Accordion cerrado)
  const renderHeader = () => (
    <Row>
      <Col xs={12} md={8} lg={9} xl={11}>
        <h5>{ternasFromIncumbencias.terna_padre.titulo_padre || 'Título no disponible'}</h5>
        <span>{ternasFromIncumbencias.terna_padre.procedencia_padre || 'Procedencia no disponible'}</span>
        <span>
          {' - ' + (ternasFromIncumbencias.terna_padre.resolucion_padre || 'Resolución no disponible')}
        </span>
      </Col>
    </Row>
  );

  return (
    <Accordion className="form-group">
      <Card>
        {/* Título cerrado */}
        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={handleClickLoadDetail}>
          {renderHeader()}
        </Accordion.Toggle>

        {/* Contenido expandido */}
        <Accordion.Collapse eventKey="0">
          <Card.Body ref={cardBodyRef}>
            {isLoading ? (
              <Spinner animation="border" />
            ) : showErrorMsg ? (
              <p>No se pudo cargar el detalle.</p>
            ) : (
              <>
                {/* Para cada hija, un bloque independiente */}
                {ternasFromIncumbencias.ternas_hijas?.map((hija, index) => (
                  <div key={index} className="p-3 border rounded">
                    {/* Ejemplo: título a la izquierda, badge de estado a la derecha */}
                    <Row className="align-items-center">
                      <Col>
                        <strong>Terna Unificada: {hija.titulo_nombre || 'Título no disponible'}</strong>
                        <br />
                        <small>
                          {hija.procedencia_nombre || 'Procedencia no disponible'}
                          {' | '}
                          {hija.resolucion_nombre || 'Resolución no disponible'}
                          {' | '}
                          {hija.fecha_creacion || 'Fecha no disponible'}
                        </small>
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default MockedIncumbenciaAccordion;
