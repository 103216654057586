import React, { useEffect, useState } from 'react';
import { Col, Row, Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { ScreenWithFabButtons } from '../../../ui/ScreenWithFabButtons';
import { Loader } from '../../../ui/Loader';
import { LimitSelect } from '../../../ui/LimitSelect/LimitSelect';
import MockedIncumbenciaAccordion from './components/MockedIncumbenciaAccordion';
import Paginacion from '../../../generics/Paginacion';
import InputDatePicker from '../../../generics/InputDatePicker';
import { sincronizacionConstants } from '../../utils/sincronizacionConstants';
import { SearchTernaToSincroMock } from '../../search/SearchTernaToSincroMock';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import * as Service from "../../SincronizacionService";

export const SincroList = () => {
  const defaultInputsValues = {
    tituloSeleccionado: null,
    procedenciaSeleccionada: null,
    resolucionSeleccionada: null,
    fecha_creacion_desde: null,
    fecha_creacion_hasta: null,
  };

  const [inputsForm, setInputsForm] = useState(defaultInputsValues);
  const [searching, setSearching] = useState(false);
  const [typeOfSearch, setTypeOfSearch] = useState(sincronizacionConstants.DISPONIBLES.id);
  const [ternasFromIncumbencias, setTernasFromIncumbencias] = useState([]);
  const [detalleIncumbencias, setDetalleIncumbencias] = useState({});
  const [selectedChecks, setSelectedChecks] = useState({});
  const [page, setPage] = useState(1);
  const [cantidadElementos, setCantidadElementos] = useState(0);
  const location = useLocation();
  const [visitedDetails, setVisitedDetails] = useState({});
  const [titulos, setTitulos] = useState([]);
  const [procedencias, setProcedencias] = useState([]);
  const [resoluciones, setResoluciones] = useState([]);

  const mockDetails = [
    {
      id: 1,
      apendice: { id: 'A1' },
      folio: 'F1',
      area: { nombre: 'Área 1' },
      tipoEscuela: { nombre: 'Tipo Escuela 1' },
      cargo: { nombre: 'Cargo 1' },
      asignatura: { nombre: 'Asignatura 1' },
      especialidad: { nombre: 'Especialidad 1' },
      tipoIncumbencia: { nombre: 'Condición 1' },
      puntaje: 10,
      sumaOT: true,
    },
    {
      id: 2,
      apendice: { id: 'A2' },
      folio: 'F2',
      area: { nombre: 'Área 2' },
      tipoEscuela: { nombre: 'Tipo Escuela 2' },
      cargo: { nombre: 'Cargo 2' },
      asignatura: { nombre: 'Asignatura 2' },
      especialidad: { nombre: 'Especialidad 2' },
      tipoIncumbencia: { nombre: 'Condición 2' },
      puntaje: 20,
      sumaOT: false,
    },
  ];

  useEffect(() => {
    console.log("inputsForm actualizado:", inputsForm);
  }, [inputsForm]);

  useEffect(() => {
    setInputsForm(defaultInputsValues);
  }, [typeOfSearch]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSearching(true);
  
        let data;
        const isMigrados = typeOfSearch === sincronizacionConstants.MIGRADOS.id;
        const params = isMigrados ? { migrados: true } : params;
  
        if (typeOfSearch === sincronizacionConstants.PENDIENTES.id || isMigrados) {
          data = await Service.getTernasUnificadasPendientes(params);
        } else {
          data = await Service.getTernasUnificadas();
        }
  
        const titulosSet = new Set();
        const procedenciasSet = new Set();
        const resolucionesSet = new Set();
  
        data.elementos.forEach(({ terna_padre, ternas_hijas }) => {
          if (terna_padre) {
            titulosSet.add(terna_padre.titulo_padre);
            procedenciasSet.add(terna_padre.procedencia_padre);
            resolucionesSet.add(terna_padre.resolucion_padre);
          }
  
          ternas_hijas.forEach((hija) => {
            titulosSet.add(hija.titulo_nombre);
            procedenciasSet.add(hija.procedencia_nombre);
            resolucionesSet.add(hija.resolucion_nombre);
          });
        });
  
        setTitulos(Array.from(titulosSet).map((titulo, index) => ({
          value: index + 1,
          label: titulo
        })));
        setProcedencias(Array.from(procedenciasSet).map((procedencia, index) => ({
          value: index + 1,
          label: procedencia
        })));
        setResoluciones(Array.from(resolucionesSet).map((resolucion, index) => ({
          value: index + 1,
          label: resolucion
        })));
  
        setTernasFromIncumbencias(data.elementos);
        setCantidadElementos(data.elementos.length);
      } catch (err) {
        console.error('Error al obtener los datos:', err);
      } finally {
        setSearching(false);
      }
    };
  
    fetchData();
  }, [typeOfSearch]);
 
  const buscarElementos = async () => {
    setSearching(true);
  
    const params = {};
  
    if (inputsForm.tituloSeleccionado) {
      params.titulo = inputsForm.tituloSeleccionado;
    }
    if (inputsForm.procedenciaSeleccionada) {
      params.procedencia = inputsForm.procedenciaSeleccionada;
    }
    if (inputsForm.resolucionSeleccionada) {
      params.resolucion = inputsForm.resolucionSeleccionada;
    }
    if (inputsForm.fecha_creacion_desde) {
      params.fecha_creacion_desde = inputsForm.fecha_creacion_desde;
    }
    if (inputsForm.fecha_creacion_hasta) {
      params.fecha_creacion_hasta = inputsForm.fecha_creacion_hasta;
    }
    if (typeOfSearch === sincronizacionConstants.MIGRADOS.id) {
      params.migrados = true;
    }
  
    try {
      let response;
  
      if (typeOfSearch === sincronizacionConstants.PENDIENTES.id) {
        response = await Service.getTernasUnificadasPendientes(params);
      } else if (typeOfSearch === sincronizacionConstants.MIGRADOS.id) {
        response = await Service.getTernasUnificadasPendientes(params);
      } else {
        response = await Service.getTernasUnificadas(params);
      }
  
      const titulosSet = new Set();
      const procedenciasSet = new Set();
      const resolucionesSet = new Set();
  
      response.elementos.forEach(({ terna_padre, ternas_hijas }) => {
        if (terna_padre) {
          titulosSet.add(terna_padre.titulo_padre);
          procedenciasSet.add(terna_padre.procedencia_padre);
          resolucionesSet.add(terna_padre.resolucion_padre);
        }
  
        ternas_hijas.forEach((hija) => {
          titulosSet.add(hija.titulo_nombre);
          procedenciasSet.add(hija.procedencia_nombre);
          resolucionesSet.add(hija.resolucion_nombre);
        });
      });
  
      setTitulos(Array.from(titulosSet).map((titulo, index) => ({
        value: index + 1,
        label: titulo
      })));
      setProcedencias(Array.from(procedenciasSet).map((procedencia, index) => ({
        value: index + 1,
        label: procedencia
      })));
      setResoluciones(Array.from(resolucionesSet).map((resolucion, index) => ({
        value: index + 1,
        label: resolucion
      })));
  
      setTernasFromIncumbencias(response.elementos);
      setCantidadElementos(response.elementos.length);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    } finally {
      setSearching(false);
    }
  };  

  const handleLoadDetail = ({ terna_id }) => {
    const detailKey = `${terna_id}`;
    setDetalleIncumbencias((prev) => ({ ...prev, [detailKey]: mockDetails }));

    setVisitedDetails((prev) => ({
      ...prev,
      [detailKey]: true,
    }));

    if (!visitedDetails[detailKey]) {
      const initialChecks = {};
      mockDetails.forEach((detail) => {
        initialChecks[detail.id] = true;
      });

      setSelectedChecks((prev) => ({
        ...prev,
        [terna_id]: initialChecks,
      }));
    }
  };

  useEffect(() => {
    buscarElementos();
  }, []);

  const handleChangeDate = (date, controlId) => {
    setInputsForm((prevState) => ({
      ...prevState,
      [controlId]: date,
    }));
  };

  const handleSincronizar = () => {
    console.log("Sincronizando elementos seleccionados:", selectedChecks);
  };

  const isAnyChecked = Object.values(selectedChecks).some(checked => checked);

  const handleChangeInputs = (inputName, value) => {
    console.log(`Cambiando ${inputName} a`, value);
    setInputsForm((prevState) => ({
      ...prevState,
      [inputName]: value ? { value: value.value, label: value.label } : null,
    }));
  };

  const fetchPendientes = async (migrados) => {
    try {
      setSearching(true);

      const params = migrados ? { migrados: true } : {};
      const data = await Service.getTernasUnificadasPendientes(params);

      setTernasFromIncumbencias(data.elementos);
      setCantidadElementos(data.elementos.length);
    } catch (err) {
      console.error('Error al obtener los datos de pendientes:', err);
    } finally {
      setSearching(false);
    }
  };

  return (
    <ScreenWithFabButtons
      hideSearchButton={false}
      hideCleanButton={false}
      returnPath={location.state?.returnPath}
      searchButtonDisabled={searching}
      cleanButtonDisabled={searching}
      onClickSearch={buscarElementos}
    >
      <h1>Sincronización: Ternas Unificadas</h1>

      <hr></hr>

      <Row>
        <Col sm={6} md={4} lg={3}>
          <InputDatePicker
            label={"Fecha creación desde"}
            selected={inputsForm.fecha_creacion_desde}
            onChange={date => handleChangeDate(date, "fecha_creacion_desde")}
          />
        </Col>
        <Col sm={6} md={4} lg={3}>
          <InputDatePicker
            label={"Fecha creación hasta"}
            selected={inputsForm.fecha_creacion_hasta}
            onChange={date => handleChangeDate(date, "fecha_creacion_hasta")}
          />
        </Col>
        {
          typeOfSearch == sincronizacionConstants.MIGRADOS.id && <>
            <Col sm={6} md={4} lg={3}>
              <InputDatePicker
                label={"Fecha creación desde"}
                selected={inputsForm.fecha_creacion_desde}
                onChange={date => handleChangeDate(date, "fecha_creacion_desde")}
              />
            </Col>
            <Col sm={6} md={4} lg={3}>
              <InputDatePicker
                label={"Fecha creación hasta"}
                selected={inputsForm.fecha_creacion_hasta}
                onChange={date => handleChangeDate(date, "fecha_creacion_hasta")}
              />
            </Col>
          </>
        }
      </Row>

      <hr />

      <Row>
        <Col>
          <SearchTernaToSincroMock
            titulos={titulos}
            procedencias={procedencias}
            resoluciones={resoluciones}
            ternasFromIncumbencias={ternasFromIncumbencias}
            inputsForm={inputsForm}
            setInputsForm={setInputsForm}
            searching={searching}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {searching ? (
            <Loader />
          ) : ternasFromIncumbencias.length > 0 ? (
            ternasFromIncumbencias.map((terna) => {
              const detailKey = `${terna.terna_padre.id}`;
              return (
                <MockedIncumbenciaAccordion
                  key={detailKey}
                  ternasFromIncumbencias={terna}
                  arrIncumbencias={detalleIncumbencias[detailKey] || []}
                  withCheckbox={typeOfSearch == sincronizacionConstants.DISPONIBLES.id}
                  selectedChecks={selectedChecks[terna.id] || {}}
                  handleLoadDetail={() => handleLoadDetail({ terna_id: terna.id })}
                  setSelectedChecks={(checks) =>
                    setSelectedChecks((prev) => ({
                      ...prev,
                      [terna.id]: checks,
                    }))
                  }
                />
              );
            })
          ) : (
            <p>No hay elementos para sincronizar</p>
          )}
          <Row className="align-items-center mt-3 w-100">
            <Col className="d-flex justify-content-start col-auto">
              <Paginacion
                filasPorPaginas={10}
                totalItems={cantidadElementos}
                currentPage={page}
                onChangePage={(e, value) => setPage(value)}
              />
            </Col>
            <Col className="d-flex justify-content-end col-auto">
              <Button
                variant="success"
                onClick={handleSincronizar}
                disabled={!isAnyChecked}
                className="mb-3"
              >
                Sincronizar Incumbencias
              </Button>
            </Col>
          </Row>

        </Col>
      </Row>
    </ScreenWithFabButtons>
  );
};
