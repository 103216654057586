import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import Select from "react-select";
import Input from '../../ui/Input';
import { useDispatch, useSelector } from 'react-redux';
import { cleanFab, searchFab } from '../../store/screen/screenActions';
import { saveFilters } from '../../store/search/searchActions';
import { reactSelectFormUtils } from '../../utils/reactSelectFormUtils';
import * as Service from "../SincronizacionService";

export const SearchTernaToSincroMock = ({
  ternasFromIncumbencias,
  inputsForm,
  setInputsForm,
  searching,
  titulos,
  procedencias,
  resoluciones,
}) => {
  const [resolucionSeleccionada, setResolucionSeleccionada] = useState('');
  const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState('');
  const [tituloSeleccionado, setTituloSeleccionado] = useState('');
  const [resetChecks, setResetChecks] = useState(0);

  useEffect(() => {
    console.log('Estados actualizados:', { titulos, procedencias, resoluciones });
  }, [titulos, procedencias, resoluciones]);

  const handleTituloChange = (e) => {
    setTituloSeleccionado(e.target.value);
    setInputsForm(prevState => ({
      ...prevState,
      tituloSeleccionado: e.target.value
    }));
    console.log('Nuevo Título Seleccionado:', e.target.value);
  };

  const handleProcedenciaChange = (e) => {
    setProcedenciaSeleccionada(e.target.value);
    setInputsForm(prevState => ({
      ...prevState,
      procedenciaSeleccionada: e.target.value
    }));
    console.log('Nueva Procedencia Seleccionada:', e.target.value);
  };

  const handleResolucionChange = (e) => {
    setResolucionSeleccionada(e.target.value);
    setInputsForm(prevState => ({
      ...prevState,
      resolucionSeleccionada: e.target.value
    }));
    console.log('Nueva Resolución Seleccionada:', e.target.value);
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()} className="w-100">
      <Row>
        <Col sm={12} md={6} lg={3}>
          <Input
            type="react-select"
            controlId="titulo"
            name="titulo"
            label="Nombre de título"
            value={tituloSeleccionado}
            methodOnChange={handleTituloChange}
            elements={() => Promise.resolve({ elementos: titulos })}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombre" }}
            setterElementSelect={setTituloSeleccionado}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            syncElements={titulos}
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Input
            type="react-select"
            controlId="procedencia"
            name="procedencia"
            label="Procedencia"
            value={procedenciaSeleccionada}
            methodOnChange={handleProcedenciaChange}
            elements={() => Promise.resolve({ elementos: procedencias })}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: 'id', label: 'nombre' }}
            setterElementSelect={setProcedenciaSeleccionada}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            syncElements={procedencias}
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Input
            type="react-select"
            controlId="resolucion"
            name="resolucion"
            label="Resolución"
            value={resolucionSeleccionada}
            methodOnChange={handleResolucionChange}
            elements={() => Promise.resolve({ elementos: resoluciones })}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: 'id', label: 'nombre' }}
            setterElementSelect={setResolucionSeleccionada}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            syncElements={resoluciones}
          />
        </Col>
      </Row>
    </Form>
  );
};
