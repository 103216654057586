export const concurrenciasConstants = {
  cantidadMaximaTernasPorConcurrencia: 3,
  cantidadMinimaTernasPorConcurrencia: 2,
}

export const tipoInforme = {
  POR_AREA_CARGO: "areacargo",
  POR_TERNA: "terna",
  TOPOLOGICO: "topologico",
  CRUZADOS: "cruzados",
  INCUMBENCIAS_TERNA: "incumbencias",
}

export const incumbenciasConstants = {
  TERNAS_INCUMBENCIA: "ternas",
  CARGOS_INCUMBENCIA: "cargos",
  TERNAS_CONCURRENCIA: "ternas_concurrencias",
  CARGOS_CONCURRENCIA: "cargos_concurrencias",
}

export const ticketsConstants = {
  PENDIENTES: { label: "pendientes", id: 10 },
  RESUELTOS: { label: "resueltos", id: 11 },
}

export const clonadorConstants = {
  CARGOS: "cargo",
  TERNAS: "terna",
  ASIGNATURAS: "asignatura",
}

export const tipoDocumentoClonador = {
  ORIGINAL: "original",
  DESTINO: "destino",
}

export const apiURLs = {
  API_URL: window.Configs.REACT_APP_API_URL,
  API_ADMIN_URL: window.Configs.REACT_APP_API_URL + "/admin",
  API_ADMIN_USUARIOS_URL: window.Configs.REACT_APP_API_URL + "/admin/usuario",
  API_ADMIN_ROLES_URL: window.Configs.REACT_APP_API_URL + "/admin/rol",
  API_MAESTROS_ESPECIALIDAD: window.Configs.REACT_APP_API_URL + "/maestros/especialidad",
  API_MAESTROS_AREA: window.Configs.REACT_APP_API_URL + "/maestros/area",
  API_MAESTROS_NIVEL: window.Configs.REACT_APP_API_URL + "/maestros/nivel",
  API_MAESTROS_TIPO_ESCUELA: window.Configs.REACT_APP_API_URL + "/maestros/tipo_escuela",
  API_MAESTROS_TIPO_ESCUELA_CODIGO: window.Configs.REACT_APP_API_URL + "/maestros/tipo_escuela/codigo",
  API_MAESTROS_ASIGNATURA: window.Configs.REACT_APP_API_URL + "/maestros/asignatura",
  /* CARGOS  */
  API_MAESTROS_CARGO_GETCARGO: window.Configs.REACT_APP_API_URL + "/maestros/cargo/getCargos",
  API_MAESTROS_CARGO: window.Configs.REACT_APP_API_URL + "/maestros/cargo",

  /* APENDICE */
  API_MAESTROS_APENDICE: window.Configs.REACT_APP_API_URL + "/maestros/apendice",
  API_MAESTROS_APENDICE_ACTUAL: window.Configs.REACT_APP_API_URL + "/maestros/apendice/apendice_actual",

  /* INCUMBENCIA */
  API_MAESTROS_INCUMBENCIA: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia",
  API_CLONAR_INCUMBENCIAS_TERNA: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/clonar",
  API_CLONAR_INCUMBENCIAS_CARGO: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/clonarPorCargoAsignaturaEspecialidad",
  API_TIPO_INCUMBENCIAS: window.Configs.REACT_APP_API_URL + "/maestros/tipo_incumbencias",
  API_INCUMBENCIAS_UNIFICACION: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/unificacion",

  API_UNIFICAR_TERNAS: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/unificar",
  API_RELACIONAR_TERNAS: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/relacionar",

  /* INFORMES */
  API_INFORMES_TERNAS_AREA_CARGO: window.Configs.REACT_APP_API_URL + "/informes/informe2",
  API_INFORMES_CRUZADOS_AREA_CARGO: window.Configs.REACT_APP_API_URL + "/informes/cruzadosPorAreaCargo",
  API_INFORMES_TOPOLOGICO: window.Configs.REACT_APP_API_URL + "/informes/topologico",
  API_INFORMES_GET_TERNAS_CON_INCUMBENCIAS: window.Configs.REACT_APP_API_URL + "/informes/concurrencia_por_terna",

  API_INFORMES_EQUIVALENCIAS: window.Configs.REACT_APP_API_URL + "/informes/equivalencia/porTerna",
  API_INFORMES_TRONCALES: window.Configs.REACT_APP_API_URL + "/informes/troncal/porTerna",
  API_INFORMES_INTERMEDIOS: window.Configs.REACT_APP_API_URL + "/informes/intermedios/porTerna",

  API_INFORMES_GENERAR_TERNA_TITULOS: window.Configs.REACT_APP_API_URL + "/ternas/terna/informe/ternasPorTitulos",
  API_INFORMES_GENERAR_TERNA_RESOLUCIONES: window.Configs.REACT_APP_API_URL + "/ternas/resolucion/informe/getPdfResoluciones",
  API_INFORMES_GENERAR_TERNA_PROCEDENCIAS: window.Configs.REACT_APP_API_URL + "/ternas/procedencia/informe/getPdfProcedencias",
  API_INFORMES_GENERAR_INCUMB_BY_TERNA_1RA_VISTA: window.Configs.REACT_APP_API_URL + "/informes/getPdfIncumbenciasConcurrenciasPorTernas",
  API_INFORMES_GENERAR_INCUMB_BY_TERNA: window.Configs.REACT_APP_API_URL + "/informes/getPdfIncumbenciasPorTerna",
  API_INFORMES_GENERAR_CRUZADOS: window.Configs.REACT_APP_API_URL + "/informes/getPdfCruzadosPorAreaCargo",
  API_INFORMES_GENERAR_CRUZADOS_POR_CALIDAD: window.Configs.REACT_APP_API_URL + "/informes/getPdfCruzadosPorAreaCargoPorCalidad",
  API_INFORMES_GENERAR_TOPOLOGICO: window.Configs.REACT_APP_API_URL + "/informes/getPdfTopologico",
  API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO_1RA_VISTA: window.Configs.REACT_APP_API_URL + "/informes/informe2/getPdfInforme2",
  API_INFORMES_GENERAR_TERNAS_BY_AREA_CARGO: window.Configs.REACT_APP_API_URL + "/informes/getPdfTernasPorAreaCargo",
  API_INFORMES_GENERAR_EQUIVALENCIAS: window.Configs.REACT_APP_API_URL + "/informes/informe2/getPdfInformeEquivalencias",

  /*GET DESCARGA DE INFORMES*/

  API_DESCARGA_INFORME: window.Configs.REACT_APP_API_URL + "/informes/descargas",
  API_DESCARGA_ESTADO_INFORME: window.Configs.REACT_APP_API_URL + "/informes/getEstadoDocumento",
  API_GENERAR_DESCARGA_PDF: window.Configs.REACT_APP_API_URL + "/informes/descargar",

  /* TICKETS */

  API_TICKETS: window.Configs.REACT_APP_API_URL + "/tickets",
  API_TIPO_TICKETS: window.Configs.REACT_APP_API_URL + "/tickets/tipos",
  API_TICKETS_MODULOS: window.Configs.REACT_APP_API_URL + "/tickets/modelos",

  /* TERNAS */
  API_TERNAS_TERNA: window.Configs.REACT_APP_API_URL + "/ternas/terna",
  API_TERNAS_TITULO: window.Configs.REACT_APP_API_URL + "/ternas/titulo",
  API_TERNAS_PROCEDENCIA: window.Configs.REACT_APP_API_URL + "/ternas/procedencia",
  API_TERNAS_RESOLUCION: window.Configs.REACT_APP_API_URL + "/ternas/resolucion",
  API_TERNAS_ORGANISMO: window.Configs.REACT_APP_API_URL + "/ternas/organismos",
  API_TERNAS_UNIFICACION: window.Configs.REACT_APP_API_URL + "/ternas/terna/unificacion",

  /* TITULOS */
  // API_TITULOS_TRONCAL: window.Configs.REACT_APP_API_URL + "/ternas/titulo_troncal",
  // API_TITULOS_INTERMEDIO: window.Configs.REACT_APP_API_URL + "/ternas/titulo_intermedio",
  API_TITULOS_EQUIVALENTE: window.Configs.REACT_APP_API_URL + "/ternas/titulo_equivalente",
  API_GET_TITULOS_ITF_CAMBIAR_ENDPOINT: window.Configs.REACT_APP_API_URL + "/titulos/itf", //TODO CAMBIAR ENDPOINT
  API_TITULOS_ITF: window.Configs.REACT_APP_API_URL + "/titulos/itf",
  API_TITULOS_ITF_POR_TERNA: window.Configs.REACT_APP_API_URL + "/informes/itf/porTerna",

  API_TERNAS_TIPO_RESOLUCION: window.Configs.REACT_APP_API_URL + "/genericos/tipo_resolucion",
  API_TERNAS_TIPO_TITULO: window.Configs.REACT_APP_API_URL + "/genericos/tipo_titulo",
  API_TERNAS_NOTAS: window.Configs.REACT_APP_API_URL + "/maestros/nota",
  API_TERNAS_REGLAS: window.Configs.REACT_APP_API_URL + "/maestros/regla",
  API_GENERICOS_TIPO_DOCUMENTO: window.Configs.REACT_APP_API_URL + "/genericos/tipo_documento",
  // API_GENERICOS_ORGANISMOS: window.Configs.REACT_APP_API_URL + "/genericos/organismos",
  API_GENERICOS_TIPO_RESOLUCION: window.Configs.REACT_APP_API_URL + "/genericos/tipo_resolucion",
  API_GENERICOS_ESTADOS: window.Configs.REACT_APP_API_URL + "/genericos/estados",

  /* SINCRONIZACION */
  API_SINCRO_TERNAS_BUSCAR: window.Configs.REACT_APP_API_URL + "/ternas/terna/buscarTernasParaSincro",
  API_SINCRO_TERNAS_BUSCAR_PENDIENTE: window.Configs.REACT_APP_API_URL + "/ternas/terna/buscarTernasPendientesParaSincro",
  API_SINCRO_TERNAS: window.Configs.REACT_APP_API_URL + "/ternas/terna/sincro",

  API_SINCRO_ESPECIALIDADES_BUSCAR: window.Configs.REACT_APP_API_URL + "/maestros/especialidad/buscarEspecialidadesParaSincro",
  API_SINCRO_ESPECIALIDADES_BUSCAR_PENDIENTE: window.Configs.REACT_APP_API_URL + "/maestros/especialidad/buscarEspecialidadesPendientesParaSincro",
  API_SINCRO_ESPECIALIDADES: window.Configs.REACT_APP_API_URL + "/maestros/especialidad/sincro",

  API_SINCRO_CARGOS_BUSCAR: window.Configs.REACT_APP_API_URL + "/maestros/cargo/buscarCargosParaSincro",
  API_SINCRO_CARGOS_BUSCAR_PENDIENTE: window.Configs.REACT_APP_API_URL + "/maestros/cargo/buscarCargosPendientesParaSincro",
  API_SINCRO_CARGOS: window.Configs.REACT_APP_API_URL + "/maestros/cargo/sincro",

  API_SINCRO_INCUMBENCIAS_BUSCAR: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/buscarIncumbenciasParaSincro",
  API_SINCRO_INCUMBENCIAS_BUSCAR_DETAIL: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/buscarIncumbenciasParaSincroDetail",
  API_SINCRO_INCUMBENCIAS_BUSCAR_PENDIENTE: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/buscarIncumbenciasPendientesParaSincro",
  API_SINCRO_INCUMBENCIAS_BUSCAR_PENDIENTE_DETAIL: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/buscarIncumbenciasPendientesParaSincroDetail",

  API_SINCRO_INCUMBENCIAS: window.Configs.REACT_APP_API_URL + "/maestros/incumbencia/sincro",

  API_SINCRO_ASIGNATURAS_BUSCAR: window.Configs.REACT_APP_API_URL + "/maestros/asignatura/buscarAsignaturasParaSincro",
  API_SINCRO_ASIGNATURAS_BUSCAR_PENDIENTE: window.Configs.REACT_APP_API_URL + "/maestros/asignatura/buscarAsignaturasPendientesParaSincro",
  API_SINCRO_ASIGNATURAS: window.Configs.REACT_APP_API_URL + "/maestros/asignatura/sincro",

  API_SINCRO_TERNAS_UNIFICADAS: window.Configs.REACT_APP_API_URL + "/ternas/terna/buscarUnificacionesParaSincro",

  API_SINCRO_TERNAS_UNIFICADAS_PENDIENTES: window.Configs.REACT_APP_API_URL + "/ternas/terna/buscarUnificacionesPendientesParaSincro",

};

export const routePaths = {
  LOGIN: "/login",
  HOME: "/home",

  /* MENUES DASHBOARD */
  HOME_ABMS: "/home/abms",
  HOME_TERNAS: "/home/ternas",
  HOME_INCUMBENCIAS: "/home/incumbencias",
  HOME_CONFIGURACIONES: "/home/configuraciones",
  HOME_INFORMES: "/home/informes",
  HOME_DESCARGA_INFORMES: "/home/informes/descargas",
  HOME_SINCRONIZACIONES: "/home/configuraciones/sincronizaciones",

  PROFILE: "/profile",

  ABM_USER_CREATE: "/admin/users/create",
  ABM_USER_EDIT: "/admin/users/edit",
  ABM_USER_SEARCH: "/admin/users",

  ABM_ESPECIALIDADES_CREATE: "/admin/especialidades/create",
  ABM_ESPECIALIDADES_EDIT: "/admin/especialidades/edit",
  ABM_ESPECIALIDADES_APPROVAL: "/admin/especialidades/approval",
  ABM_ESPECIALIDADES_SEARCH: "/admin/especialidades",

  ABM_CARGOS_SEARCH: "/admin/cargos",
  ABM_CARGOS_CREATE: "/admin/cargos/create",
  ABM_CARGOS_EDIT: "/admin/cargos/edit",
  ABM_CARGOS_APPROVAL: "/admin/cargos/approval",

  ABM_ASIGNATURAS_SEARCH: "/admin/asignaturas",
  ABM_ASIGNATURAS_CREATE: "/admin/asignaturas/create",
  ABM_ASIGNATURAS_EDIT: "/admin/asignaturas/edit",
  ABM_ASIGNATURAS_APPROVAL: "/admin/asignaturas/approval",

  ABM_TERNA_SEARCH: "/admin/ternas",
  ABM_TERNA_CREATE: "/admin/ternas/create",
  ABM_TERNA_EDIT: "/admin/ternas/edit",
  ABM_TERNA_APPROVAL: "/admin/ternas/approval",
  ABM_TERNA_TICKET: "/admin/ternas/ticket",
  ABM_TERNA_UNIFICADOR_SEARCH: "/admin/ternas/unificador",
  ABM_TERNAS_UNIFICADAS_SEARCH: "/admin/ternas/unificadas",
  ABM_TERNA_UNIFICADOR_CREATE: "/admin/ternas/unificador/create",

  ABM_TITULOS_TRONCALES_SEARCH: "/admin/titulos/troncales",
  ABM_TITULOS_TRONCALES_CREATE: "/admin/titulos/troncales/create",
  ABM_TITULOS_TRONCALES_CREATE_SEARCH_TRONCAL: "/admin/titulos/troncales/create/troncal",
  ABM_TITULOS_TRONCALES_CREATE_SEARCH_ORIENTACION: "/admin/titulos/troncales/create/orientacion",
  ABM_TITULOS_TRONCALES_EDIT: "/admin/titulos/troncales/edit",
  ABM_TITULOS_TRONCALES_APPROVAL: "/admin/titulos/troncales/approval",

  ABM_TITULOS_ITF_SEARCH: "/admin/titulos/ITF",
  ABM_TITULOS_ITF_CREATE: "/admin/titulos/ITF/create",
  ABM_TITULOS_ITF_CREATE_SEARCH_PRINCIPAL: "/admin/titulos/ITF/create/principal",
  ABM_TITULOS_ITF_CREATE_SEARCH_RELACIONADO: "/admin/titulos/ITF/create/relacionado",
  ABM_TITULOS_ITF_EDIT: "/admin/titulos/ITF/edit",
  ABM_TITULOS_ITF_APPROVAL: "/admin/titulos/ITF/approval",

  ABM_TITULOS_INTERMEDIOS_SEARCH: "/admin/titulos/intermedios",
  ABM_TITULOS_INTERMEDIOS_CREATE: "/admin/titulos/intermedios/create",
  ABM_TITULOS_INTERMEDIOS_CREATE_SEARCH_ORIGINAL: "/admin/titulos/intermedios/create/original",
  ABM_TITULOS_INTERMEDIOS_CREATE_SEARCH_FINAL: "/admin/titulos/intermedios/create/final",
  ABM_TITULOS_INTERMEDIOS_EDIT: "/admin/titulos/intermedios/edit",
  ABM_TITULOS_INTERMEDIOS_APPROVAL: "/admin/titulos/intermedios/approval",

  ABM_TITULOS_EQUIVALENTES_SEARCH: "/admin/titulos/equivalentes",
  ABM_TITULOS_EQUIVALENTES_CREATE: "/admin/titulos/equivalentes/create",
  ABM_TITULOS_EQUIVALENTES_CREATE_SEARCH_ORIGINAL: "/admin/titulos/equivalentes/create/original",
  ABM_TITULOS_EQUIVALENTES_CREATE_SEARCH_EQUIVALENTE: "/admin/titulos/equivalentes/create/equivalente",
  ABM_TITULOS_EQUIVALENTES_EDIT: "/admin/titulos/equivalentes/edit",
  ABM_TITULOS_EQUIVALENTES_APPROVAL: "/admin/titulos/equivalentes/approval",

  /**
   * ESPIN LABS DEV
   */
  ABM_ESCUELA_SEARCH: "/admin/escuela",
  ABM_ESCUELA_CREATE: "/admin/escuela/create",
  ABM_ESCUELA_EDIT: "/admin/escuela/edit",
  ABM_ESCUELA_APPROVAL: "/admin/escuela/approval",

  ABM_APENDICE_SEARCH: "/admin/apendice",
  ABM_APENDICE_CREATE: "/admin/apendice/create",
  ABM_APENDICE_EDIT: "/admin/apendice/edit",


  /* SINCRONIZACION */
  SINCRO_PANEL: "/admin/sincronizacion",
  SINCRO_PANEL_TERNAS: "/admin/sincronizacion/ternas",
  SINCRO_PANEL_CARGOS: "/admin/sincronizacion/cargos",
  SINCRO_PANEL_INCUMBENCIAS: "/admin/sincronizacion/incumbencias",
  SINCRO_PANEL_ASIGNATURAS: "/admin/sincronizacion/asignaturas",
  SINCRO_PANEL_ESPECIALIDADES: "/admin/sincronizacion/especialidades",
  SINCRO_TERNAS_UNIFICADAS: "/admin/sincronizacion/ternas-unificadas",

  /* INCUMBENCIAS */
  ABM_INCUMBENCIAS_SEARCH: "/admin/incumbencias",
  ABM_INCUMBENCIAS_SEARCH_TERNAS: "/admin/incumbencias/" + incumbenciasConstants.TERNAS_INCUMBENCIA,
  ABM_INCUMBENCIAS_SEARCH_CARGOS: "/admin/incumbencias/" + incumbenciasConstants.CARGOS_INCUMBENCIA,

  ABM_INCUMBENCIAS_CREATE: "/admin/incumbencias/create",
  ABM_INCUMBENCIAS_CREATE_TERNAS: "/admin/incumbencias/create/" + incumbenciasConstants.TERNAS_INCUMBENCIA,
  ABM_INCUMBENCIAS_CREATE_CARGOS: "/admin/incumbencias/create/" + incumbenciasConstants.CARGOS_INCUMBENCIA,

  ABM_INCUMBENCIAS_EDIT: "/admin/incumbencias/edit",
  ABM_INCUMBENCIAS_EDIT_TERNAS: "/admin/incumbencias/edit/" + incumbenciasConstants.TERNAS_INCUMBENCIA,
  ABM_INCUMBENCIAS_EDIT_CARGOS: "/admin/incumbencias/edit/" + incumbenciasConstants.CARGOS_INCUMBENCIA,

  ABM_INCUMBENCIAS_CLONADOR: "/admin/incumbencias/clonador",
  ABM_INCUMBENCIAS_CLONADOR_TERNAS: "/admin/incumbencias/clonador/" + clonadorConstants.TERNAS,
  ABM_INCUMBENCIAS_CLONADOR_CARGOS: "/admin/incumbencias/clonador/" + clonadorConstants.CARGOS,
  ABM_INCUMBENCIAS_CLONADOR_ASIGNATURAS: "/admin/incumbencias/clonador/" + clonadorConstants.ASIGNATURAS,

  ABM_INCUMBENCIAS_UNIFICADOR_SEARCH: "/admin/incumbencias/unificador",
  ABM_INCUMBENCIAS_UNIFICADOR_CREATE: "/admin/incumbencias/unificador/create",

  /* INFORMES */
  INFORMES_SEARCH_TERNAS_BY_AREA_CARGO: "/admin/informes/ternasPorAreaCargo",
  INFORMES_SEARCH_CRUZADO: "/admin/informes/cruzado",
  INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA: "/admin/informes/incumbencias",
  INFORMES_SEARCH_TERNAS: "/admin/informes/ternas",
  INFORMES_SEARCH_TOPOLOGICO: "/admin/informes/topologico",
  INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA_DETAIL: "/admin/informes/incumbencias/detail",
  INFORMES_SEARCH_TERNAS_BY_AREA_CARGO_DETAIL: "/admin/informes/ternasPorAreaCargo/detail",

  /* TICKETS */
  ABM_TICKETS_SEARCH: "/admin/tickets",
  ABM_TICKETS_SEARCH_RESUELTOS: "/admin/tickets/" + ticketsConstants.RESUELTOS.label,
  ABM_TICKETS_SEARCH_PENDIENTES: "/admin/tickets/" + ticketsConstants.PENDIENTES.label,
};

export const alertConstants = {
  SUCCESS: "success",
  ERROR: "error",
  CLEAR: "clear",
  INFO: "info",
  WARNING: "warning",
  MULTIPLE: "multiple",
  HIDE_ONE_ALERT: "HIDE_ONE_ALERT",
};

//No se usa mas, se usa PERMISOS_MAP
export const permissionLevels = {
  USUARIOS_VER: { "USUARIOS_VER": 0 },
  USUARIOS_CREAR: { "USUARIOS_CREAR": 0 },

  USUARIOS_EDITAR: { "USUARIOS": 100 },
  USUARIOS_ALL: { "USUARIOS": 1000 },

  ESPECIALIDADES_ALL: { "ESPECIALIDADES": 1000 },
  ESPECIALIDADES_APROBAR: { "ESPECIALIDADES_APROBAR": 0 },
  ESPECIALIDADES_CLONAR: { "ESPECIALIDADES": 100 },
  ESPECIALIDADES_CREAR: { "ESPECIALIDAD_CREAR": 0 },
  ESPECIALIDADES_EDITAR: { "ESPECIALIDAD_EDITAR": 0 },
  ESPECIALIDADES_ELIMINAR: { "ESPECIALIDAD_ELIMINAR": 0 },
  VER_MODULO_ESPECIALIDAD: { "VER_MODULO_ESPECIALIDAD": 0 },

  TIPO_ESCUELA_APROBAR: { "TIPO_ESCUELA_APROBAR": 0 },
  TIPO_ESCUELA_CREAR: { "TIPO_ESCUELA_CREAR": 0 },
  TIPO_ESCUELA_EDITAR: { "TIPO_ESCUELA_EDITAR": 0 },
  TIPO_ESCUELA_ELIMINAR: { "TIPO_ESCUELA_ELIMINAR": 0 },
  VER_MODULO_TIPO_ESCUELA: { "VER_MODULO_TIPO_ESCUELA": 0 },

  ASIGNATURA_ALTA: { "ASIGNATURA_ALTA": 0 },
  ASIGNATURA_APROBAR: { "ASIGNATURA_APROBAR": 0 },
  ASIGNATURA_BAJA: { "ASIGNATURA_BAJA": 0 },
  ASIGNATURA_BUSCAR: { "ASIGNATURA_BUSCAR": 0 },
  ASIGNATURA_CREAR: { "ASIGNATURA_CREAR": 0 },
  ASIGNATURA_EDITAR: { "ASIGNATURA_EDITAR": 0 },
  ASIGNATURA_ELIMINAR: { "ASIGNATURA_ELIMINAR": 0 },
  ASIGNATURA_PUBLICAR: { "ASIGNATURA_PUBLICAR": 0 },
  VER_MODULO_ASIGNATURA: { "VER_MODULO_ASIGNATURA": 0 },

  CARGO_ALTA: { "CARGO_ALTA": 0 },
  CARGO_APROBAR: { "CARGO_APROBAR": 0 },
  CARGO_BAJA: { "CARGO_BAJA": 0 },
  CARGO_BUSCAR: { "CARGO_BUSCAR": 0 },
  CARGO_CREAR: { "CARGO_CREAR": 0 },
  CARGO_EDITAR: { "CARGO_EDITAR": 0 },
  CARGO_ELIMINAR: { "CARGO_ELIMINAR": 0 },
  CARGO_PUBLICAR: { "CARGO_PUBLICAR": 0 },
  VER_MODULO_CARGO: { "VER_MODULO_CARGO": 0 },

  TRONCAL_VER: { "TITULO_TRONCAL_VER": 0 },
  TRONCAL_CREAR: { "TITULO_TRONCAL_CREAR": 0 },
  TRONCAL_EDITAR: { "TITULO_TRONCAL_EDITAR": 0 },
  TRONCAL_ELIMINAR: { "TITULO_TRONCAL_ELIMINAR": 0 },

  INTERMEDIO_VER: { "TITULO_INTERMEDIO_VER": 0 },
  INTERMEDIO_CREAR: { "TITULO_INTERMEDIO_CREAR": 0 },
  INTERMEDIO_EDITAR: { "TITULO_INTERMEDIO_EDITAR": 0 },
  INTERMEDIO_ELIMINAR: { "TITULO_INTERMEDIO_ELIMINAR": 0 },

  INCUMBENCIA_VER: { "INCUMBENCIA_VER": 0 },
  INCUMBENCIA_ALTA: { "INCUMBENCIA_ALTA": 0 },
  INCUMBENCIA_BAJA: { "INCUMBENCIA_BAJA": 0 },
  INCUMBENCIA_CREAR: { "INCUMBENCIA_CREAR": 0 },
  INCUMBENCIA_EDITAR: { "INCUMBENCIA_EDITAR": 0 },
  INCUMBENCIA_ELIMINAR: { "INCUMBENCIA_ELIMINAR": 0 },

  EQUIVALENTE_VER: { "TITULO_EQUIVALENTE_VER": 0 },
  EQUIVALENTE_CREAR: { "TITULO_EQUIVALENTE_CREAR": 0 },
  EQUIVALENTE_EDITAR: { "TITULO_EQUIVALENTE_EDITAR": 0 },
  EQUIVALENTE_ELIMINAR: { "TITULO_EQUIVALENTE_ELIMINAR": 0 },

  TERNA_VER: { "TERNA_VER": 0 },
  TERNA_CREAR: { "TERNA_CREAR": 0 },
  TERNA_EDITAR: { "TERNA_EDITAR": 0 },
  TERNA_ELIMINAR: { "TERNA_ELIMINAR": 0 },
  TERNA_ALL: { "TERNAS": 1000 },

  SINCRONIZACION_VER: { "SINCRONIZACION_VER": 0 },

  VER_MODULO_APENDICE: { "VER_MODULO_APENDICE": 0 },

  VER_MODULO_CONFIGURACION: { "VER_MODULO_CONFIGURACION": 0 },

}





export const typesConstants = {
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAIL: "REGISTER_FAIL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
  SET_MESSAGE: "SET_MESSAGE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  SAVE_FILTER: "SAVE_FILTERS",
  CLEAR_FILTER: "CLEAR_FILTER"
};

export const roleConstants = {
  ADMIN: 1
}

export const configGeneral = {
  COLOR_APENDICE: 25
}

//TODO ADAPTAR VALORES UNA VEZ CARGADOS
export const estadosConstans = {
  MIGRADO: 1,
  BORRADOR: 2,
  PENDIENTE_APROBACION: 3,
  PENDIENTE_MIGRACION: 4,
  EN_REVISION: 5,
  PARA_SER_AUTORIZADO: 6,
  PUBLICADO: 7,
  APROBADO: 8,
  DESHABILITADO: 9
}

export const tipoCampo = {
  AREA: 1,
  ESPECIALIDAD: 2,
  RESOLUCION: 3,
  NIVEL: 4,
  TIPO_ESCUELA: 5,
  INPUT: 6
}

export const tooltipsText = {
  ALTA: "Dar de alta",
  BAJA: "Dar de baja",
  EDITAR: "Editar",
  DETALLE: "Ver detalle",
  INFORME: "Ver informe",
  TICKET: "Generar ticket",
  ELIMINAR: "Eliminar",
  PUBLICAR: "Publicar",
  APROBAR: "Aprobar",
  REINICIAR: "Reiniciar",
  CANCELAR: "Cancelar",
  VER_MAS: "Ver mas",
  VER_MENOS: "Ver menos",
  CERRAR_TICKET: "Cerrar ticket",
  NOTA_CUSTOM: "Ingresar nota custom",
  NOTA_DEFAULT: "Ver notas default",
}

export const tipoBusquedaInforme = {
  TITULO: "Titulo",
  RESOLUCIONES: "Resoluciones",
  PROCEDENCIAS: "Procedencias"
};

export const paginado = {
  FILAS_POR_PAGINAS: 10,
};

export const tipoSexo = {
  FEMENINO: { value: "F", label: "Femenino" },
  MASCULINO: { value: "M", label: "Masculino" },
}

export const tiposTitulos = {
  TRONCAL: "troncal"
}

export const notasCargo = [
  { id: 1, descripcion: "Si posee otro título de la misma procedencia y resolución, solo se valorará uno de ellos." },
  { id: 2, descripcion: "Si posee otro título de la misma resolución sólo se valorará el de mayor puntaje." },
  { id: 3, descripcion: "Si posee otro título de la misma procedencia y resolución, solo se valorará el de mayor puntaje." },
  { id: 4, descripcion: "Si posee otro título de la misma resolución, sólo se valorará uno de ellos." },
  { id: 5, descripcion: 'Si posee el título "(acá va el nombre del título)", de la misma procedencia y resolución, solo se valorará uno de ellos.' },
  { id: 6, descripcion: 'Si posee el título "(acá va el nombre del título)", de la misma procedencia y resolución, solo se valorará uno de ellos' },
  { id: 7, descripcion: 'Si posee el título "(acá va el nombre del título)", de la misma procedencia y resolución, solo se valorará el de mayor puntaje.' },
  { id: 8, descripcion: "Si posee otro título de la misma procedencia y resolución, solo se valorará uno de ellos." },
]

export const tipoCargos = {
  BASE: { value: 1, label: "BASE" },
  CONDUCCION: { value: 2, label: "Conducción" }
};

export const validationErrorMsg = {
  isRequired: "Debe ingresar un",
  isMulti: "Debe ingresar por lo menos un",
}

export const typeOfActions = {
  CREATE: "CREATE",
  SEARCH: "SEARCH",
  EDIT: "EDIT",
  APPROVAL: "APPROVAL",
  PUBLISH: "PUBLISH",
  GENERAR_TICKET_MOD: "GENERAR_TICKET_MOD",
}