import React, { useState, useEffect } from 'react';
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import { Table, Button, Row, Col, Spinner } from 'react-bootstrap';
import Input from '../../ui/Input';
import Paginacion from '../../generics/Paginacion';
import * as Service from '../../descargas/DescargasService';
import { apiURLs } from '../../utils/Constants';
import { Loader } from '../../ui/Loader';

export const InformesDownload = () => {
    const [page, setPage] = useState(1);
    const [cantidad, setCantidad] = useState(0);
    const [tempFilters, setTempFilters] = useState({
        id_documento: "", nombre_doc: "", formato_doc: "", tipo_informe: "", estado_documento: ""
    });
    const [filters, setFilters] = useState({
        id_documento: "", nombre_doc: "", formato_doc: "", tipo_informe: "", estado_documento: ""
    });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadingId, setDownloadingId] = useState(null);

    const fetchData = async ( currentPage ) => {
        setLoading(true);
        try {
            await Service.getDocumentos(
                { ...formattedFilters(filters), page: currentPage, limit: 10 },
                (response) => {
                    setData(response.elementos || []);
                    setCantidad(response.cantidad || 0)
                    setPage(response.page);
                    setLoading(false);
                    return response;
                },
                (error) => {
                    console.error("Error al obtener documentos:", error);
                    setLoading(false);
                }
            );
        } catch (error) {
            console.error("Error en la petición:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData( page )
    }, [filters]);

    useEffect(() => {
        if (cantidad === 0) {
            setPage(1);
        }
    }, [cantidad]);    

    const handleChangePage = async (event, value) => {
        const resp = await fetchData( value );
        /* setData(resp.elementos); */
    }

    const handleTempFilterChange = (field, value) => {
        setTempFilters({ ...tempFilters, [field]: value });
    };

    const handleApplyFilters = () => {
        setFilters(tempFilters);
    };

    const handleClearFilters = () => {
        setTempFilters({ id_documento: "", nombre_doc: "", formato_doc: "", tipo_informe: "", estado_documento: "" });
        setFilters({ id_documento: "", nombre_doc: "", formato_doc: "", tipo_informe: "", estado_documento: "" });
    };

    const formattedFilters = (filters) => Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [
            key,
            (key === "tipo_informe" || key === "estado_documento")
                ? value
                : (typeof value === "string" && isNaN(value) && value !== "" ? `"${value}"` : value)
        ])
    );

    const handleDownload = async (documentoId, formato) => {
        setDownloadingId(documentoId);
        try {
            await Service.generarDescargaPDF(formato, { documento_id: documentoId });
        } catch (error) {
            console.error("Error en la descarga:", error);
        } finally {
            setDownloadingId(null);
        }
    };

    return (
        <ScreenWithFabButtons
            hideSearchButton={false}
            hideCleanButton={false}
            onSearch={handleApplyFilters}
            onClean={handleClearFilters}
        >
            <h1>Informes</h1>

            <Row className="mb-3">
                <Col xs={12} md={3}>
                    <Input
                        type="input"
                        controlId="filter-id"
                        label="ID"
                        value={tempFilters.id_documento}
                        onChange={(e) => handleTempFilterChange("id_documento", e.target.value)}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <Input
                        type="input"
                        controlId="filter-nombre"
                        label="Nombre"
                        value={tempFilters.nombre_doc}
                        onChange={(e) => handleTempFilterChange("nombre_doc", e.target.value)}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <label htmlFor="filter-formato">Tipo Documento</label>
                    <select
                        id="filter-formato"
                        className="form-control"
                        value={tempFilters.formato_doc}
                        onChange={(e) => handleTempFilterChange("formato_doc", e.target.value)}
                    >
                        <option value="">Todos</option>
                        <option value="PDF">PDF</option>
                        <option value="Word">CSV</option>
                    </select>
                </Col>
                <Col xs={12} md={3}>
                    <label htmlFor="filter-tipoInforme">Tipo Informe</label>
                    <select
                        id="filter-tipoInforme"
                        className="form-control"
                        value={tempFilters.tipo_informe}
                        onChange={(e) => handleTempFilterChange("tipo_informe", e.target.value)}
                    >
                        <option value="">Todos</option>
                        <option value="Ternas">Ternas</option>
                        <option value="Incumbencias de Títulos">Incumbencias de Títulos</option>
                        <option value="Búsqueda por Cargo o Materia">Búsqueda por Cargo o Materia</option>
                        <option value="Vademecum">Vademecum</option>
                        <option value="Topologico">Topologico</option>
                    </select>
                </Col>
                <Col xs={12} md={3}>
                    <label htmlFor="filter-estadoDocumento">Estado Documento</label>
                    <select
                        id="filter-estadoDocumento"
                        className="form-control"
                        value={tempFilters.estado_documento}
                        onChange={(e) => handleTempFilterChange("estado_documento", e.target.value)}
                    >
                        <option value="">Todos</option>
                        <option value="Finalizado">Finalizado</option>
                        <option value="Iniciado">Iniciado</option>
                        <option value="Error">Error</option>
                    </select>
                </Col>
            </Row>

            <hr />

            {loading && <Loader></Loader>}
            {!loading && 
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre de Documento</th>
                                <th>Fecha de Creación</th>
                                <th>Tipo Documento</th>
                                <th>Tipo Informe</th>
                                <th>Filtros</th>
                                <th>Estado</th>
                                <th>Descarga</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(doc => (
                                <tr key={doc.id_documento}>
                                    <td>{doc.id_documento}</td>
                                    <td>{doc.nombre_documento}</td>
                                    <td>{new Date(doc.created_at).toLocaleDateString()}</td>
                                    <td>{doc.formato_documento}</td>
                                    <td>{doc.tipo_informe}</td>
                                    <td>{doc.filtros}</td>
                                    <td>{doc.estado_documento}</td>
                                    <td>
                                        <div style={{ width: '120px' }}>
                                            {downloadingId === doc.id_documento ? (
                                                <Button variant="primary" block disabled>
                                                    <Spinner animation="border" size="sm" variant="light" />
                                                </Button>
                                            ) : doc.formato_documento === 'CSV' ? (
                                                <Button variant="primary" block onClick={() => handleDownload(doc.id_documento, 'CSV')}>
                                                    🡣 .CSV
                                                </Button>
                                            ) : (
                                                <Button variant="primary" block onClick={() => handleDownload(doc.id_documento, 'PDF')}>
                                                    🡣 .PDF
                                                </Button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody> 
                    </Table>
                    {cantidad > 0 && (
                        <div style={{ marginTop: "40px" }}>
                            <Paginacion totalItems={cantidad} currentPage={page} onChangePage={handleChangePage} />
                        </div>
                    )}
                </>
            }

        </ScreenWithFabButtons>
    );
};
