import { formatDate } from "./commons";
import authHeader from "./auth-header";
import axios from "axios";
import { error } from "../store/alerts/alertActions";
import store from "../store/store";
import { apiURLs } from "./Constants";


class RequestService {

  getQueryString(obj) {
    let queryString = [];
    for (var property in obj)
      if (obj.hasOwnProperty(property)) {
        let propertyValue = obj[property];
        if (propertyValue === null || propertyValue === "") {
          continue;
        }
        if (propertyValue instanceof Date) {
          propertyValue = formatDate(propertyValue);
        }
        queryString.push(encodeURIComponent(property) + "=" + encodeURIComponent(propertyValue));
      }
    let qString = queryString.join("&");
    if (qString != "") {
      qString = "?" + qString;
    }
    return qString;
  }

  async sendRequest(method, uri, params = {}, isMountedRef) {
    const finishRequest = async (resp) => {
      if (isMountedRef) {
        if (isMountedRef.current == false) {
          throw new Error("Componente desmontado...")
        };
      }
      return resp;
    }

    switch (method) {
      case 'POST':
        return finishRequest(await axios.post(uri, params, { headers: authHeader() }));

      case 'PUT':
        return finishRequest(await axios.put(uri, params, { headers: authHeader() }));

      case 'GET':
        let parametros = this.getQueryString(params);
        return finishRequest(await axios.get(uri + parametros, { headers: authHeader() }));

      case 'PATCH':
        return finishRequest(await axios.patch(uri, params, { headers: authHeader() }));

      case 'DELETE':
        return finishRequest(await axios.delete(uri, { headers: authHeader() }));

      default:
        throw new Error("Falta fetch method");
    }
  }

  async downloadPDF(uri, params = {}, fileName, abortSource) {
    let parametros = this.getQueryString(params);
    try {
      const response = await axios({
        method: 'get',
        url: uri + parametros,
        responseType: 'blob',
        headers: authHeader(),
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(`Download progress: ${progress}%`);
        },
        cancelToken: abortSource.token
      })

      /* let blob = new Blob([response.data], { type: 'application/pdf' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.pdf`;
      link.click(); */

      return true;
    } catch (err) {
      // throw new Error("Hubo un error al descargar el PDF");
      store.dispatch(error(err.response.data.message));

    }
  }

  async downloadPDFInforme(uri, params = {}, fileName, abortSource) {
    let parametros = this.getQueryString(params);
    try {
        const response = await axios({
            method: 'get',
            url: uri + parametros,
            responseType: 'blob',
            headers: authHeader(),
            onDownloadProgress: (progressEvent) => {
                const progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                );
                console.log(`Download progress: ${progress}%`);
            },
            cancelToken: abortSource?.token // Evitar error si abortSource es null
        });

        let blob = new Blob([response.data], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.pdf`;
        link.click();

        return true; // Retornar éxito
    } catch (err) {
        store.dispatch(error(err.response?.data?.message || "Error al descargar PDF"));
        return false; // Retornar error explícitamente
    }
}

async downloadCSVInforme(uri, params = {}, fileName, abortSource) {
  let parametros = this.getQueryString(params);
  try {
      const response = await axios({
          method: 'get',
          url: uri + parametros,
          responseType: 'blob', // Se espera un blob para CSV
          headers: authHeader(),
          onDownloadProgress: (progressEvent) => {
              const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
              );
              console.log(`Download progress: ${progress}%`);
          },
          cancelToken: abortSource?.token // Evitar error si abortSource es null
      });

      let blob = new Blob([response.data], { type: 'text/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.csv`;
      link.click();

      return true; // Retornar éxito
  } catch (err) {
      store.dispatch(error(err.response?.data?.message || "Error al descargar CSV"));
      return false; // Retornar error explícitamente
  }
}


  async downloadCSV(uri, params = {}, fileName) {
    let parametros = this.getQueryString(params);
    try {
      const response = await axios({
        method: 'get',
        url: uri + parametros,
        responseType: 'blob',
        headers: authHeader()
      })

      /* let blob = new Blob(["\ufeff", response.data], { type: 'text/csv; charset=utf-8' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.csv`;
      link.click(); */

      return true;
    } catch (err) {
      // throw new Error("Hubo un error al descargar el CSV");
      store.dispatch(error(err.response.data.message));

    }
  }

  async iniciarDescargaSegundoPlanoCSV(uri, params = {}) {
    let parametros = this.getQueryString(params);
    try {
      const response = await axios({
        method: 'get',
        url: uri + parametros,
        responseType: 'blob',
        headers: authHeader()
      })

      return true;
    } catch (err) {
      store.dispatch(error(err.response.data.message));

    }
  }

  async iniciarDescargaSegundoPlanoPDF(uri, params = {}, abortSource) {
    let parametros = this.getQueryString(params);
    try {
      const response = await axios({
        method: 'get',
        url: uri + parametros,
        responseType: 'blob',
        headers: authHeader(),
        cancelToken: abortSource.token
      })

      return true;
    } catch (err) {
      store.dispatch(error(err.response.data.message));

    }
  }


}

export default new RequestService()