import React, { useEffect, useRef, useState } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Dialog from '../../generics/dialog'
import { error } from '../../store/alerts/alertActions'
import { OPCIONES_DESCARGAS, agregarDescargas } from '../../store/descargas/descargasActions.'
import { saveFilters } from '../../store/search/searchActions'
import { Loader } from '../../ui/Loader'
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons'
import { estadosConstans, routePaths } from '../../utils/Constants'
import { getOptionsInformeTernasPorAreaCargo } from '../InformesService'
import { FormSearchByAreaCargo } from './FormSearchByAreaCargo'
import { FormTableByAreaCargo } from './FormTableByAreaCargo'
import { PERMISOS_MAP } from '../../utils/Permisos'
import PrivateComponent from '../../authentication/PrivateComponent'

export const SearchByAreaCargo = () => {
    const inputFormInitialState = {}

    const [inputsForm, setInputsForm] = useState(inputFormInitialState);
    const [elementos, setElementos] = useState();
    const [cantidadElementos, setCantidadElementos] = useState();
    const [page, setPage] = useState(1);
    const [searching, setSearching] = useState(false);

    const searchState = useSelector(st => st.search);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const isMountedRef = useRef(true);

    const {
        arrActivas,
        arrCompletadas
    } = useSelector(st => st.descargas);

    const tituloDescargas = "Descarga en proceso" + (arrCompletadas.length > 0 ? ` (${arrCompletadas.length})` : "");

    const cleanResults = () => {
        setElementos("");
        setCantidadElementos("");
        setPage(1)
        setSearching(false)
    }

    const resetForm = () => {
        setInputsForm(inputFormInitialState)
        cleanResults();
    }

    const searchAreaCargo = async (filtros = {}) => {
        cleanResults();
        if (!filtros.page) filtros.page = 1;
        setSearching(true);

        try {
            const resp = await getOptionsInformeTernasPorAreaCargo(filtros, isMountedRef)
            setElementos(resp.elementos);
            setCantidadElementos(resp.cantidad);
            setPage(resp.page);
        }
        catch (err) { console.log(err.message) }
        finally { setSearching(false); }
    }

    const handleChangePage = (event, value) => {
        const filtros = { ...searchState.inputsForm, page: value }
        dispatch(saveFilters({ ...searchState, inputsForm: filtros }));

        searchAreaCargo(filtros);
        setPage(value);
    };

    const viewDetail = (selectedElement) => {
        const stateDetail = {
            selectedElement,
            inputsForm,
            returnData: {
                elementos,
                cantidadElementos,
                page
            }
        }
        history.push({
            pathname: routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO_DETAIL,
            state: stateDetail
        });
    }

    const handleClickGenerarPDF = async (event) => {
        let filtros = { ...inputsForm }
        if (filtros.page) delete filtros.page

        try {
            dispatch(agregarDescargas([{
                title: "Informe / Terna por Área o Cargo (PDF)",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_BY_AREA_CARGO_PRIMERA_VISTA,
                filtros: filtros,
                descripcion: "Descripcion de los filtros empleados.",
                // fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const handleClickGenerarCSV = async (event) => {
        let filtros = { ...inputsForm }
        if (filtros.page) delete filtros.page

        try {
            dispatch(agregarDescargas([{
                title: "Informe / Terna por Área o Cargo (CSV)",
                opcion: OPCIONES_DESCARGAS.CSV.INFORME_BY_AREA_CARGO_PRIMERA_VISTA,
                filtros: filtros,
                descripcion: "Otra descripcion de los filtros empleados. 1",
                // fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    useEffect(() => {
        if (location?.state && Object.keys(location.state).length > 0) {
            if (location.state.elementos) setElementos(location.state.elementos)
            if (location.state.cantidadElementos) setCantidadElementos(location.state.cantidadElementos)
            if (location.state.page) setPage(location.state.page)
        }
    }, []);

    return (
        <ScreenWithFabButtons
            searchButtonDisabled={searching}
            cleanButtonDisabled={searching || !elementos}
            hideSearchButton={false}
            hideCleanButton={false}
        >
            <h1>Informes</h1>
            <h3>Búsqueda por Cargo o Materia</h3>

            {arrCompletadas.length > 0 && (
                <Alert variant="warning" className="mt-3">
                    📂 {tituloDescargas}
                </Alert>
            )}

            <FormSearchByAreaCargo
                inputsForm={inputsForm}
                setInputsForm={setInputsForm}
                resetForm={resetForm}
                searchAreaCargo={searchAreaCargo}
            />

            {!searching && <>

                {cantidadElementos > 0 &&
                    <FormTableByAreaCargo
                        elementos={elementos}
                        cantidadElementos={cantidadElementos}
                        page={page}
                        viewDetail={viewDetail}
                        handleChangePage={handleChangePage}
                    />
                }

                {cantidadElementos === 0 &&
                    <Alert variant="info">No hay ternas ni incumbencias que mostrar para los filtros completados.</Alert>}

                {(cantidadElementos > 0 || cantidadElementos === 0) &&
                    <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                        <>
                            {/*"CSV_INFORME_BY_AREA_CARGO_PRIMERA_VISTA"*/}
                            <Dialog
                                const key={(arrActivas && Array.isArray(arrActivas) && arrActivas.some(item => item.opcion === "CSV_INFORME_BY_AREA_CARGO_PRIMERA_VISTA"))
                                    ? `csv-${arrActivas.findIndex(item => item.opcion === "CSV_INFORME_BY_AREA_CARGO_PRIMERA_VISTA")}`
                                    : "csv"}
                                //preventFocus
                                action={handleClickGenerarCSV}
                                title={"Informe Total (.CSV)"}
                                variant="primary"
                                size="md"
                                btnConfirmText="Generar"
                                btnVariant="primary"
                                btnText={arrActivas.some(item => item.opcion === "CSV_INFORME_BY_AREA_CARGO_PRIMERA_VISTA") ? (
                                    <>
                                        <Spinner animation="border" size="sm" style={{ color: 'red' }} /> {" Generando Inf. (.CSV)"}
                                    </>
                                ) : "Informe Total (.CSV)"}
                                isLoadingText={"Generando informe"}
                                disabled={arrActivas.some(item => item.opcion === "CSV_INFORME_BY_AREA_CARGO_PRIMERA_VISTA")}
                                body= {<span>¿Estás seguro de generar el informe?</span>}
                            />
                            {/*"PDF_INFORME_BY_AREA_CARGO_PRIMERA_VISTA"*/}
                            <Dialog
                                const key={(arrActivas && Array.isArray(arrActivas) && arrActivas.some(item => item.opcion === "PDF_INFORME_BY_AREA_CARGO_PRIMERA_VISTA"))
                                    ? `pdf-${arrActivas.findIndex(item => item.opcion === "PDF_INFORME_BY_AREA_CARGO_PRIMERA_VISTA")}`
                                    : "pdf"}
                                //preventFocus
                                action={handleClickGenerarPDF}
                                title={"Informe Total"}
                                variant="primary"
                                size="md"
                                btnConfirmText="Generar"
                                btnVariant="primary"
                                btnText={arrActivas.some(item => item.opcion === "PDF_INFORME_BY_AREA_CARGO_PRIMERA_VISTA") ? (
                                    <>
                                        <Spinner animation="border" size="sm" style={{ color: 'red' }} /> {" Generando Inf. Total"}
                                    </>
                                ) : "Informe Total"}
                                isLoadingText={"Generando informe"}
                                body= {<span>¿Estás seguro de generar el informe?</span>}
                                disabled={arrActivas.some(item => item.opcion === "PDF_INFORME_BY_AREA_CARGO_PRIMERA_VISTA")}
                            />
                        </>
                    </PrivateComponent>
                }

            </>
            }

            {searching && <Loader></Loader>}
        </ScreenWithFabButtons>
    )
}
