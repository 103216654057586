import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { estadosConstans, routePaths } from '../../utils/Constants';
import { FormSearchIncumByTerna } from './FormSearchIncumByTerna'
import { FormTableIncumByTerna } from './FormTableIncumByTerna'
import { Loader } from '../../ui/Loader';
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import { useRef } from 'react';
import { saveFilters } from '../../store/search/searchActions';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { getTernasConIncumbencias } from '../InformesService';
import Dialog from '../../generics/dialog';
import { error } from '../../store/alerts/alertActions';
import { agregarDescargas, OPCIONES_DESCARGAS } from '../../store/descargas/descargasActions.';
import PrivateComponent from '../../authentication/PrivateComponent';
import { PERMISOS_MAP } from '../../utils/Permisos';

// export const defaultEstadosSearchIncumByTerna = [
//     { id: 1, nombre: "Migrado" },
//     { id: 7, nombre: "Publicado" },
//     { id: 8, nombre: "Aprobado" },

// ];

export const SearchIncumByTerna = () => {

    const inputsInitialValue = {
        titulos: true,
        otros_titulos: true,
        estado: [estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.MIGRADO]
    }

    const [inputsForm, setInputsForm] = useState(inputsInitialValue);
    const [elementos, setElementos] = useState();
    const [cantidadElementos, setCantidadElementos] = useState();
    const [page, setPage] = useState(1);

    const [mostrarGenerarPdf, setMostrarGenerarPdf] = useState()
    const [searching, setSearching] = useState(false);
    const filtrosGuardados = useSelector((state) => state.search);

    const cantidadMaximaElementos = 100;

    const searchState = useSelector(st => st.search)
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const isMountedRef = useRef(true);

    const {
        arrActivas,
        arrCompletadas
    } = useSelector(st => st.descargas);

    const tituloDescargas = "Descarga en proceso" + (arrCompletadas.length > 0 ? ` (${arrCompletadas.length})` : "");

    const cleanResults = () => {
        setSearching(false)
        setInputsForm(inputsInitialValue)
        setElementos("");
        setCantidadElementos("");
    }

    const searchTernas = async (filtros = {}) => {
        setSearching(true);
        try {
            const response = await getTernasConIncumbencias(filtros, isMountedRef);
            setElementos(response.elementos);
            setCantidadElementos(response.cantidad);
            setPage(filtros.page)
            setMostrarGenerarPdf(response.cantidad <= cantidadMaximaElementos)
        }
        catch (err) { console.log(err.message) }
        finally { setSearching(false); }
    }

    const handleChangePage = (event, value) => {
        const filtros = { ...searchState.filtros, page: value }
        dispatch(saveFilters({ ...searchState, filtros }));

        searchTernas(filtros);
        setPage(value);
    };


    const getDetailByTerna = (terna) => {
        history.push({
            pathname: routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA_DETAIL,
            state: {
                terna,
                area: inputsForm?.area,
                area_busquedaAvanzada: inputsForm?.area_busquedaAvanzada,
                areaSeleccionada: filtrosGuardados?.reactSelectValues?.areaSeleccionada,
                returnData: {
                    elementos,
                    cantidadElementos,
                    page
                }
            }
        });
    }

    const handleClickGenerarPDF = async (event) => {
        let filtros = { ...inputsForm }
        if (filtros.page) delete filtros.page

        try {
            dispatch(agregarDescargas([{
                title: "Informe / Incumbencias - Concurrencias por terna (PDF)",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA,
                filtros: filtros,
                descripcion: "Descripcion de los filtros empleados.",
                // fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const handleClickGenerarCSV = async (event) => {
        let filtros = { ...inputsForm }
        if (filtros.page) delete filtros.page

        try {
            dispatch(agregarDescargas([{
                title: "Informe / Incumbencias - Concurrencias por terna (CSV)",
                opcion: OPCIONES_DESCARGAS.CSV.INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA,
                filtros: filtros,
                descripcion: "Otra descripcion de los filtros empleados. 1",
                // fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    useEffect(() => {
        if (location?.state && Object.keys(location.state).length > 0) {
            if (location.state.elementos) setElementos(location.state.elementos)
            if (location.state.cantidadElementos) setCantidadElementos(location.state.cantidadElementos)
            if (location.state.page) setPage(location.state.page)
        }
    }, []);


    return (
        <ScreenWithFabButtons
            searchButtonDisabled={searching}
            cleanButtonDisabled={searching || !elementos}
            hideSearchButton={false}
            hideCleanButton={false}
        >
            <h1>Informes</h1>
            <h3>Incumbencias de Títulos</h3>

            {arrCompletadas.length > 0 && (
                <Alert variant="warning" className="mt-3">
                    📂 {tituloDescargas}
                </Alert>
            )}
        
            <FormSearchIncumByTerna
                inputsForm={inputsForm}
                setInputsForm={setInputsForm}
                searchTernas={searchTernas}
                cleanResults={cleanResults}
            />
            <div className="mt-5 pt-2">

                {!searching &&
                    <>
                        {cantidadElementos > 0 && <FormTableIncumByTerna
                            getDetailByTerna={getDetailByTerna}
                            handleChangePage={handleChangePage}
                            elementos={elementos}
                            cantidadElementos={cantidadElementos}
                            page={page}
                        />}

                        {cantidadElementos === 0 &&
                            <Alert variant="info">Esta terna no tiene incumbencias asignadas.</Alert>}

                        {(cantidadElementos > 0 || cantidadElementos === 0) &&
                            <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                                {/*CSV_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA*/}
                                <Dialog
                                    const key={(arrActivas && Array.isArray(arrActivas) && arrActivas.some(item => item.opcion === "CSV_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA"))
                                        ? `csv-${arrActivas.findIndex(item => item.opcion === "CSV_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA")}`
                                        : "csv"}
                                    action={handleClickGenerarCSV}
                                    title={"Informe Total (.CSV)"}
                                    variant="primary"
                                    size="md"
                                    btnConfirmText="Generar"
                                    btnVariant="primary"
                                    btnText={arrActivas.some(item => item.opcion === "CSV_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA") ? (
                                        <>
                                            <Spinner animation="border" size="sm" style={{ color: 'red' }} /> {" Generando Inf. (.CSV)"}
                                        </>
                                    ) : "Informe Total (.CSV)"}
                                    isLoadingText={"Generando informe"}
                                    disabled={arrActivas.some(item => item.opcion === "CSV_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA")}
                                    body={<span>¿Estás seguro de generar el informe?</span>}
                                />
                                {/*PDF_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA*/}
                                <Dialog
                                    const key={(arrActivas && Array.isArray(arrActivas) && arrActivas.some(item => item.opcion === "PDF_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA"))
                                        ? `pdf-${arrActivas.findIndex(item => item.opcion === "PDF_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA")}`
                                        : "pdf"}
                                    action={handleClickGenerarPDF}
                                    title={"Informe Total"}
                                    variant="primary"
                                    size="md"
                                    btnConfirmText="Generar"
                                    btnVariant="primary"
                                    btnText={arrActivas.some(item => item.opcion === "PDF_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA") ? (
                                        <>
                                            <Spinner animation="border" size="sm" style={{ color: 'red' }} /> {" Generando Inf. Total"}
                                        </>
                                    ) : "Informe Total"}
                                    isLoadingText={"Generando informe"}
                                    body= {<span>¿Estás seguro de generar el informe?</span>}
                                    disabled={arrActivas.some(item => item.opcion === "PDF_INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA")}
                                />
                                
                            </PrivateComponent>
                        }
                    </>
                }

                {searching && <Loader></Loader>}
            </div>
        </ScreenWithFabButtons>
    )
}
