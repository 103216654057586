import React from 'react';
import { Accordion, Card, Col, Row, Badge } from 'react-bootstrap';
import { UnificacionAccordion } from './UnificadorAccordion';

export const UnificadorSearchRow = ({ unificacion }) => {
    const getEstadoColor = (estado) => {
        switch (estado) {
            case 'Aprobado':
                return 'success'; // Verde
            case 'Deshabilitado':
                return 'danger'; // Rojo
            case 'Pendiente':
                return 'warning'; // Amarillo
            default:
                return 'secondary'; // Gris
        }
    };

    const formatData = (procedencia, resolucion) => {
        return `${procedencia}${procedencia && resolucion ? ' | ' : ''}${resolucion} | `;
    };

    return (
        <Accordion className="form-group">
            <Card key={unificacion.id}>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    <Row className="align-items-center">
                        <Col xs={12} md={8} lg={9} xl={10}>
                            <h5>{"Terna Unificadora: " + unificacion.terna.titulo.nombre}</h5>
                            <span className="accordion-header-text">
                                {formatData(unificacion.terna.procedencia.nombre, unificacion.terna.resolucion.nombre)}
                                <Badge variant={getEstadoColor('Aprobado')}>
                                    Aprobado
                                </Badge>
                            </span>
                        </Col>
                    </Row>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                    <UnificacionAccordion unificacion={unificacion} />
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};
