import React, { useEffect } from "react";
import { Row } from "react-bootstrap";

const Paginacion = ({  
  totalItems,  
  currentPage,  
  filasPorPaginas = 10,  
  onChangePage,  
  paginasPorBloque = 5,  
  className,  
  ...props  
}) => {  
  const totalPages = Math.ceil(Number(totalItems) / filasPorPaginas);  

  // Cálculo del rango del bloque actual  
  const currentBlock = Math.floor((currentPage - 1) / paginasPorBloque);  
  const startPage = currentBlock * paginasPorBloque + 1;  
  const endPage = Math.min(startPage + paginasPorBloque - 1, totalPages);  
 
  const visiblePages = [];  
  for (let i = startPage; i <= endPage; i++) {  
    visiblePages.push(i);  
  }  

  const handlePageChange = (event, page) => {  
    let newPage = currentPage;  

    if (typeof page == "string") {  
      switch (page) {  
        case "prevBlock":  
          newPage = Math.max(startPage - 1, 1);  
          break;  
        case "nextBlock":  
          newPage = Math.min(endPage + 1, totalPages);  
          break;  
        case "prevPage":  
          newPage = Math.max(currentPage - 1, 1);  
          break;  
        case "nextPage":  
          newPage = Math.min(currentPage + 1, totalPages);  
          break;  
        case "firstPage":  
          newPage = 1;  
          break;  
        case "lastPage":  
          newPage = totalPages;  
          break;  
        default:  
          break;  
      }  
    } else {  
      newPage = page;  
    }  

    onChangePage(event, newPage);  
  };  

  return (  
    <Row className={`form-group pe-all ${className || ""}`}>  
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>  
        {/* Botón de inicio */}  
        <button  
          style={{  
            border: "1px solid #ddd",  
            borderRadius: "5px",  
            padding: "5px 10px",  
            cursor: currentPage == 1 ? "not-allowed" : "pointer",  
            backgroundColor: currentPage == 1 ? "#e0e0e0" : "white",
            color: currentPage == 1 ? "#a0a0a0" : "black",
            opacity: currentPage == 1 ? 0.6 : 1,
          }}  
          onClick={(event) => handlePageChange(event, "firstPage")}  
          disabled={currentPage == 1}  
        >  
          Inicio  
        </button>
  
        {/* Botones de navegación */}  
        <button  
          style={{  
            border: "none",  
            background: "none",  
            fontSize: "1.5rem",  
            cursor: startPage == 1 ? "not-allowed" : "pointer",  
          }}  
          onClick={(event) => handlePageChange(event, "prevBlock")}  
          disabled={startPage == 1}  
        >  
          {"<<"}  
        </button>  

        <button  
          style={{  
            border: "none",  
            background: "none",  
            fontSize: "1.2rem",  
            cursor: currentPage == 1 ? "not-allowed" : "pointer",  
          }}  
          onClick={(event) => handlePageChange(event, "prevPage")}  
          disabled={currentPage == 1}  
        >  
          {"<"}  
        </button>  

        {startPage > 1 && <span>...</span>}  
        
        {visiblePages.map((page) => (
          <button
            key={page}
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
              backgroundColor: page == currentPage ? "lightgray" : "white",
              color: page == currentPage ? "black" : "black",
              fontWeight: page == currentPage ? "bold" : "normal",
              textDecoration: page == currentPage ? "underline" : "none",
            }}
            onClick={(event) => handlePageChange(event, page)}
          >
            {page}
          </button>
        ))}

        {endPage < totalPages && <span>...</span>}  

        {/* Botones de navegación */}  
        <button  
          style={{  
            border: "none",  
            background: "none",  
            fontSize: "1.2rem",  
            cursor: currentPage == totalPages ? "not-allowed" : "pointer",  
          }}  
          onClick={(event) => handlePageChange(event, "nextPage")}  
          disabled={currentPage == totalPages}  
        >  
          {">"}  
        </button>  

        <button  
          style={{  
            border: "none",  
            background: "none",  
            fontSize: "1.5rem",  
            cursor: endPage == totalPages ? "not-allowed" : "pointer",  
          }}  
          onClick={(event) => handlePageChange(event, "nextBlock")}  
          disabled={endPage == totalPages}  
        >  
          {">>"}  
        </button>  

        {/* Botón de final */}
        <button  
          style={{  
            border: "1px solid #ddd",  
            borderRadius: "5px",  
            padding: "5px 10px",  
            cursor: currentPage == totalPages ? "not-allowed" : "pointer",  
            backgroundColor: currentPage == totalPages ? "#e0e0e0" : "white",
            color: currentPage == totalPages ? "#a0a0a0" : "black",
            opacity: currentPage == totalPages ? 0.6 : 1,
          }}  
          onClick={(event) => handlePageChange(event, "lastPage")}  
          disabled={currentPage == totalPages}  
        >  
          Final  
        </button>

        {/* Texto indicando la página actual de total páginas */}  
        <span style={{ fontSize: "1rem", fontWeight: "bold", margin: "0 10px" }}>  
          Página {currentPage} de {totalPages}  
        </span>  
      </div>  
    </Row>  
  );  
};  

export default Paginacion;
