import React, { useState } from 'react'
import { FormSearchTopologico } from './FormSearchTopologico'
import { FormTableTopologico } from './FormTableTopologico'
import * as informesService from "../InformesService"
import { Loader } from '../../ui/Loader'
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons'
import { Alert, Col, Row, Spinner } from 'react-bootstrap'
import Dialog from '../../generics/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { error } from '../../store/alerts/alertActions'
import { saveFilters } from '../../store/search/searchActions'
import { useRef } from 'react'
import { useEffect } from 'react'
import { agregarDescargas, OPCIONES_DESCARGAS } from '../../store/descargas/descargasActions.'
import PrivateComponent from '../../authentication/PrivateComponent'
import { PERMISOS_MAP } from '../../utils/Permisos'

export const SearchTopologico = () => {

    const [inputsForm, setInputsForm] = useState({});
    const [elementos, setElementos] = useState();
    const [cantidadElementos, setCantidadElementos] = useState();
    const [page, setPage] = useState(1);
    const [searching, setSearching] = useState(false);

    const cantidadMaximaElementos = 1000;
    const generarEnFormatoPDF = cantidadElementos <= cantidadMaximaElementos

    const searchState = useSelector(st => st.search);
    const dispatch = useDispatch();
    const isMountedRef = useRef(true);

    const {
        arrActivas,
        arrCompletadas
    } = useSelector(st => st.descargas);

    const tituloDescargas = "Descarga en proceso" + (arrCompletadas.length > 0 ? ` (${arrCompletadas.length})` : "");

    const cleanResults = () => {
        setElementos("");
        setCantidadElementos("");
        setPage(1)
        setSearching(false)
    }

    const resetForm = () => {
        setInputsForm({})
        cleanResults();
    }

    const searchInfoTopologico = async (filtros = {}) => {
        cleanResults();
        if (!filtros.page) filtros.page = 1;
        setSearching(true);
        dispatch(saveFilters({ inputsForm: filtros }));

        try {
            const resp = await informesService.getInformeTopologico(filtros, isMountedRef);
            setElementos(resp.elementos);
            setCantidadElementos(resp.cantidad);
            setPage(resp.page);
        }
        catch (err) { console.log(err.message) }
        finally { setSearching(false); }
    }

    const handleChangePage = (event, value) => {
        const filtros = { ...searchState.inputsForm, page: value }
        searchInfoTopologico(filtros);
        setPage(value);
    };


    const handleClickGenerarPDF = async () => {
        try {
            const filtros = searchState.inputsForm;
            dispatch(agregarDescargas([{
                title: "Informe / Topologico (PDF)",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_TOPOLOGICO,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                // fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const handleClickGenerarCSV = async () => {
        try {
            const filtros = searchState.inputsForm;
            dispatch(agregarDescargas([{
                title: "Informe / Topologico (CSV)",
                opcion: OPCIONES_DESCARGAS.CSV.INFORME_TOPOLOGICO,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                // fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }


    useEffect(() => {
        return (() => { isMountedRef.current = false })
    }, [])

    return (
        <ScreenWithFabButtons
            searchButtonDisabled={searching}
            cleanButtonDisabled={searching || !elementos}
            hideSearchButton={false}
            hideCleanButton={false}
            containerFluid={true}
        >
            <Row className="form-group container mx-auto">
                <Col>
                    <h1>Informes</h1>
                    <h3>Topologicos</h3>
                    {arrCompletadas.length > 0 && (
                        <Alert variant="warning" className="mt-3">
                            📂 {tituloDescargas}
                        </Alert>
                    )} 
                </Col>
            </Row>

            <FormSearchTopologico
                inputsForm={inputsForm}
                setInputsForm={setInputsForm}
                resetForm={resetForm}
                searchInfoTopologico={searchInfoTopologico}
            />
            {!searching &&
                <div className="i-topologico_table-container">
                    {cantidadElementos > 0 &&
                        <FormTableTopologico
                            elementos={elementos}
                            cantidadElementos={cantidadElementos}
                            page={page}
                            handleChangePage={handleChangePage}
                        />}

                    {cantidadElementos === 0 &&
                        <Alert variant="info">No hay ternas ni incumbencias que mostrar para los filtros completados.</Alert>}

                    {(cantidadElementos > 0 || cantidadElementos === 0) &&
                        <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                            {/*CSV_INFORME_TOPOLOGICO*/}
                            <Dialog
                                const key={(arrActivas && Array.isArray(arrActivas) && arrActivas.some(item => item.opcion === "CSV_INFORME_TOPOLOGICO"))
                                    ? `csv-${arrActivas.findIndex(item => item.opcion === "CSV_INFORME_TOPOLOGICO")}`
                                    : "csv"}
                                action={handleClickGenerarCSV}
                                title={"Informe Total (.CSV)"}
                                variant="primary"
                                size="md"
                                btnConfirmText="Generar"
                                btnVariant="primary"
                                btnText={arrActivas.some(item => item.opcion === "CSV_INFORME_TOPOLOGICO") ? (
                                    <>
                                        <Spinner animation="border" size="sm" style={{ color: 'red' }} /> {" Generando Inf. (.CSV)"}
                                    </>
                                ) : "Informe Total (.CSV)"}
                                isLoadingText={"Generando informe"}
                                disabled={arrActivas.some(item => item.opcion === "CSV_INFORME_TOPOLOGICO")}
                                body= {<span>¿Estás seguro de generar el informe?</span>}
                            />

                            {/*PDF_INFORME_TOPOLOGICO*/}
                            <Dialog
                                const key={(arrActivas && Array.isArray(arrActivas) && arrActivas.some(item => item.opcion === "PDF_INFORME_TOPOLOGICO"))
                                    ? `pdf-${arrActivas.findIndex(item => item.opcion === "PDF_INFORME_TOPOLOGICO")}`
                                    : "pdf"}
                                action={handleClickGenerarPDF}
                                title={"Informe Total"}
                                variant="primary"
                                size="md"
                                btnConfirmText="Generar"
                                btnVariant="primary"
                                btnText={arrActivas.some(item => item.opcion === "PDF_INFORME_TOPOLOGICO") ? (
                                    <>
                                        <Spinner animation="border" size="sm" style={{ color: 'red' }} /> {" Generando Inf. Total"}
                                    </>
                                ) : "Informe Total"}
                                isLoadingText={"Generando informe"}
                                disabled={arrActivas.some(item => item.opcion === "PDF_INFORME_TOPOLOGICO")}
                                body= {<span>¿Estás seguro de generar el informe?</span>}
                            />
                            
                        </PrivateComponent>
                    }
                </div>
            }

            {searching && <Loader></Loader>}
        </ScreenWithFabButtons>
    )
}
