import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as apendiceService from "../../apendice/ApendiceService";
import PrivateComponent from "../../authentication/PrivateComponent";
import Dialog from "../../generics/dialog";
import { validateReactInputs } from "../../helpers/validations";
import { clear } from "../../store/alerts/alertActions";
import { searchFab } from "../../store/screen/screenActions";
import { clearFilters } from "../../store/search/searchActions";
import Input from "../../ui/Input";
import { Loader } from "../../ui/Loader";
import { formatElements } from '../../ui/ReactSelect';
import { routePaths, typeOfActions } from "../../utils/Constants";
import { handlerChange, isValidNumeric, isValidText } from "../../utils/commons";
import * as especialidadesService from "../EspecialidadesService";


const EspecialidadABMForm = ({ especialidad, typeOfAction = typeOfActions.CREATE, returnPath, ...props }) => {
    const [inputsForm, setInputsForm] = useState([]);
    const [areaSeleccionada, setAreaSeleccionada] = useState("");
    const isMountedRef = useRef(null);
    const [validated, setValidated] = useState(false);
    const [wasModified, setWasModified] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const getCurrentApendice = async () => {
        if (typeOfAction == typeOfActions.CREATE) {
            apendiceService.getCurrentApendice().then(result => {
                if (isMountedRef.current) {
                    setInputsForm(data => ({ ...data, apendice: result }))
                }
            });
        }
    };

    const submitResolve = (espe) => {
        history.push(routePaths.ABM_ESPECIALIDADES_SEARCH);
        dispatch(searchFab(true));
    }

    const submitResolveWithClearFilters = () => {
        dispatch(clearFilters())
        submitResolve()
    }

    const handleSubmit = async (event) => {
        await handleValidation(especialidad => especialidadesService.approve(especialidad, submitResolve));
    };

    const handleBorrador = async (event) => {
        const mensaje = await handleValidation(async (especialidad) => {
            await especialidadesService.saveDraft(
                especialidad,
                typeOfAction == typeOfActions.CREATE ? submitResolveWithClearFilters : submitResolve,
                null
            );     
        })
        if (mensaje && mensaje.length > 0) {
            await especialidadesService.saveDraft(
                especialidad,
                typeOfAction == typeOfActions.CREATE ? submitResolveWithClearFilters : submitResolve,
                mensaje
            );
        }
    };    

    const handleEnviarAutorizar = async (supervisor) => {
        handleValidation(especialidadesService.sendToAuthorize);
    };

    const handleEnviarRevision = async (analista) => {
        handleValidation(especialidadesService.sendToRevice);
    };

    const handleValidation = async (resolve) => {
        let mensaje = "";
        const form = document.getElementById("formSearchEspecialidades");
        if (!form.checkValidity() === false) {
            if (wasModified) {
                const requiredReactInputsIds = [
                    "area",
                ];
                if (validateReactInputs(inputsForm, requiredReactInputsIds)) {

                    if (especialidad) {
                        mensaje = await especialidadesService.editEspecialidad(inputsForm, resolve)
                        if (mensaje)
                            setWasModified(false);
                    } else {
                        mensaje = await especialidadesService.createNewEspecialidad(inputsForm, resolve);
                        setWasModified(false);
                    }
                    return mensaje;
                }
            } else {
                await resolve(inputsForm);
            }
        }
        setValidated(true);
    };

    const handleChange = (e) => {
        handlerChange(setInputsForm, inputsForm, setWasModified, e)
    };

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handleChange(e);
        }
    };

    const preventDefault = (event) => {
        event.preventDefault();
    };

    const loadEspecialidadInfo = () => {
        const formato = { value: "id", label: "nombre" };
        setInputsForm(especialidad);
        setAreaSeleccionada(formatElements(especialidad.area, formato));
    }

    useEffect(() => {
        if (inputsForm.length < 1 && especialidad) {
            setWasModified(false);
            loadEspecialidadInfo();
        }
    }, [especialidad])

    useEffect(() => {
        isMountedRef.current = true;
        dispatch(clear());
        getCurrentApendice();
        return () => (isMountedRef.current = false);
    }, [dispatch]);

    return (
        <Row className="form-group">
            <Col>
                {true ? (
                    <Form
                        id="formSearchEspecialidades"
                        noValidate
                        validated={validated}
                        onSubmit={preventDefault}
                    >
                        <Row>
                            <Col md={8} lg={8}>
                                <Input
                                    type="input"
                                    controlId="nombre"
                                    label="Nombre de la especialidad"
                                    value={inputsForm.nombre ? inputsForm.nombre : ""}
                                    onChange={handleValidChange.bind(this, isValidText)}
                                    maxLength="200"
                                    required
                                    {...props}
                                ></Input>
                            </Col>

                            <Col sm={12} md={4} lg={2}>
                                <Input
                                    type="input"
                                    controlId="codDad"
                                    label="Código DAD"
                                    value={inputsForm.codDad ? inputsForm.codDad : ""}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                    required
                                    disabled={typeOfAction === typeOfActions.EDIT}
                                    {...props}
                                ></Input>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <Input
                                    type="input"
                                    controlId="folio"
                                    label="Folio"
                                    maxLength="100"
                                    value={inputsForm.folio ? inputsForm.folio : ""}
                                    onChange={handleValidChange.bind(this, isValidNumeric)}
                                    required
                                    {...props}
                                ></Input>
                            </Col>

                            <Col sm={12} md={8} lg={8}>
                                <Input
                                    type="react-select"
                                    controlId="area"
                                    name="area"
                                    label="Área"
                                    value={areaSeleccionada}
                                    methodOnChange={handleChange}
                                    elements={especialidadesService.getAreas}
                                    nameElementsResponse="data"
                                    propertiesForFormat={{ value: "id", label: "nombre" }}
                                    setterElementSelect={setAreaSeleccionada}
                                    config={{ isSearchable: true, isClearable: true }}
                                    required
                                    validated={validated}
                                    {...props}
                                ></Input>
                            </Col>
                            <Col sm={12} md={2}>
                                <Input
                                    type="input"
                                    controlId="apendice"
                                    label="Apéndice"
                                    classNameLabel="text-muted"
                                    onChange={handleChange}
                                    readOnly
                                    value={inputsForm.apendice ? inputsForm.apendice.id : "---"}
                                ></Input>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col md={12}>
                                <Input
                                    type="input"
                                    controlId="descripcion"
                                    label="Descripción"
                                    maxLength="500"
                                    value={inputsForm.descripcion ? inputsForm.descripcion : ""}
                                    onChange={handleValidChange.bind(this, isValidText)}
                                    {...props}
                                ></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {wasModified &&
                                    <PrivateComponent>
                                        <Dialog
                                            action={handleBorrador}
                                            target={especialidad}
                                            title={"Guardar como borrador"}
                                            variant="primary"
                                            size="md"
                                            btnConfirmText="Guardar"
                                            btnText="Guardar como borrador"
                                            isLoadingText={"Guardando"}
                                            btnVariant="secondary"
                                            body={<span>
                                                ¿Estás seguro de mantener la especialidad
                                                <b> {especialidad ? especialidad.nombre : ""} </b>
                                                como borrador?
                                            </span>
                                            }
                                        />
                                    </PrivateComponent>
                                }
                                {/* {false && <EspecialidadesDialog
                                    onSubmit={handleEnviarAutorizar}
                                    title="Enviar a autorizar"
                                    items={analysts}
                                    size="md"
                                    especialidad={especialidad}
                                    btnConfirmText="Enviar"
                                    name="Supervisor"
                                >
                                    Enviar a autorizar
                                </EspecialidadesDialog>
                                }
                                {false &&
                                    <EspecialidadesDialog
                                        onSubmit={handleEnviarRevision}
                                        title="Enviar a revisar"
                                        items={supervisors}
                                        especialidad={especialidad}
                                        btnConfirmText="Enviar"
                                        name="Analista"
                                    >
                                        Enviar a revisar
                                    </EspecialidadesDialog>
                                } */}
                                {(typeOfAction == typeOfActions.APPROVAL) &&
                                    <PrivateComponent>
                                        <Dialog
                                            action={handleSubmit}
                                            target={especialidad}
                                            title="Confirmar aprobación"
                                            variant="success"
                                            size="md"
                                            btnConfirmText="Aprobar"
                                            btnText="Aprobar"
                                            isLoadingText={"Aprobando"}
                                            body={<span>
                                                ¿Estás seguro de aprobar la especialidad
                                                <b> {especialidad ? especialidad.nombre : ""}</b>
                                                ?
                                            </span>
                                            }
                                        />
                                    </PrivateComponent>
                                }
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Loader></Loader>
                )}
            </Col>
        </Row>
    );
};

export default EspecialidadABMForm;
