import store from "../store/store";
import { error, info, success, warning } from "../store/alerts/alertActions";
import { history } from "../helpers/history";
import Cargo, { createCargo } from "../dtos/cargo/Cargo";
import { adaptGetCargos } from "./CargosAdapter";
const { apiURLs, estadosConstans, routePaths, paginado } = require("../utils/Constants");
const { default: requestService } = require("../utils/request.service");

//tener en cuenta cuando se agregue limite por el paginado que este metodo tmb se usa en selects
const getCargos = async (filtros = {}) => {
  let cargos = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS
  if (filtros.areas) filtros['area'] = filtros.areas.map(a => a.value);

  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_CARGO, filtros)
  const elementos = adaptGetCargos(response.data.elementos)
  elementos.forEach(cargo => {
    cargos.push(createCargo(cargo))
  });
  let cargosConcretos = await Promise.all(cargos)
  return { cantidad: response.data.cantidad, page: response.data.page, data: cargosConcretos };
}

const getCargosAsignaturas = async (filtros = {}) => {
  let cargos = []
  if (!filtros['limit']) filtros['limit'] = paginado.FILAS_POR_PAGINAS
  filtros["asignatura"] = true
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_CARGO, filtros)
  response.data.elementos.forEach(cargo => {
    cargos.push(createCargo(cargo))
  });

  let cargosConcretos = await Promise.all(cargos)
  return { cantidad: response.data.cantidad, data: cargosConcretos };
}

const getCargo = async (idCargo) => {
  let response = await requestService.sendRequest('GET', apiURLs.API_MAESTROS_CARGO + `/${idCargo}`)
  return response.data;
}

const editCargo = async (cargo, resolve) => {
  return await createEditCargo(armarCargo(cargo), resolve, 'PATCH', apiURLs.API_MAESTROS_CARGO + `/${cargo.id}`);
}

const createNewCargo = async (cargo, resolve) => {
  return await createEditCargo(armarCargo(cargo), resolve, 'POST', apiURLs.API_MAESTROS_CARGO);
}

const createEditCargo = async (cargo, resolve, typeReuest, apiURL) => {
  // const cargoArmado = armarCargo(cargo);

  await requestService.sendRequest(typeReuest, apiURL, cargo)
    .then(resp => {
      resolve(resp.data)
      return true
    }).catch(e => {
      store.dispatch(error(e.response.data.message));
      return false
    })
}

const armarCargo = (cargo) => {
  if (!cargo['asignatura']) cargo['asignatura'] = false
  let { codDad, tipoCargo, especialidades, nivel, ...body } = cargo;
  let especialidad = especialidades ? especialidades.map(a => a.value) : null;

  return {
    cod_dad: codDad,
    especialidad_id: especialidad,
    nivel: nivel || null,
    resolucion: null,
    tipo_cargo: tipoCargo?.value || null,
    ...body
  };
}

const sendToAuthorize = async (cargo) => {
  await changeState(cargo, estadosConstans.PARA_SER_AUTORIZADO, "Se envió a autorizar el cargo correctamente.")
}

const saveDraft = async (cargo, resolve) => {
  resolve(cargo);
  const mensaje = cargo.mensaje || <>Se guardó correctamente el cargo <b>{cargo.nombre}</b> como borrador.</>;

  if (cargo.mensaje) {
    await store.dispatch(info(mensaje)); // Esto debería mostrar el fondo rojo
  } else {
    await store.dispatch(success(mensaje));
  }
};

const sendToRevice = async (cargo) => {
  changeState(cargo, estadosConstans.EN_REVISION, "Se envió a revisar el cargo correctamente.")
}

const approve = async (cargo, resolve) => {
  await changeState(cargo, estadosConstans.APROBADO, <>Se aprobo el cargo <b>{cargo.nombre}</b> correctamente.</>, resolve)
}

const publish = async (cargo) => {
  await changeState(cargo, estadosConstans.PUBLICADO, <>Se publico el cargo <b>{cargo.nombre}</b> correctamente.</>)
}

const changeState = async (cargo, newState, message, resolve) => {
  try {
    await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_CARGO + `/${cargo.id}/estado`, { estado: newState });
    if (resolve) await resolve()
    if (message) store.dispatch(success(message))
  } catch (e) {
    store.dispatch(error(e.response.data.message))
  }
}

const deleteCargo = async (cargo, resolve) => {
  await requestService.sendRequest('DELETE', apiURLs.API_MAESTROS_CARGO + `/${cargo.id}`)
    .then(resp => {
      resolve()
      store.dispatch(success(<>Se elimino el cargo <b>{cargo.nombre}</b> correctamente.</>))
    })
    .catch(e => { store.dispatch(error(e.response.data.message)) })
}

const getResoluciones = async (filtros = {}) => {
  filtros['limit'] = 100;
  return await requestService.sendRequest('GET', apiURLs.API_TERNAS_RESOLUCION)
    .then(resp => {
      return resp.data;
    }).catch(e => { return [] })
}

const deactivate = async (cargo, resolve) => {
  await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_CARGO + `/${cargo.id}/switchAltaBajaCargo`, { activo: false });
  if (resolve) await resolve();
  await store.dispatch(success(<>Se desactivo el cargo <b>{cargo.nombre}</b> correctamente.</>));
}

const activate = async (cargo, resolve) => {
  await requestService.sendRequest('PUT', apiURLs.API_MAESTROS_CARGO + `/${cargo.id}/switchAltaBajaCargo`, { activo: true });
  if (resolve) await resolve();
  await store.dispatch(success(<>Se activo el cargo <b>{cargo.nombre}</b> correctamente.</>));
}

export {
  saveDraft,
  sendToAuthorize,
  createNewCargo,
  editCargo,
  deleteCargo,
  getCargos,
  getCargo,
  approve,
  sendToRevice,
  publish,
  getResoluciones,
  activate,
  deactivate
}